import { Box, Button, CircularProgress, styled, Tooltip, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { getEmbeddingsFiles, postEmbeddingsFile } from '../../services/embeddings';
import { EmbeddingsFile } from '../../Types/embeddings';
import FileCard from './FileCard';

const FileManagementDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
});

const FileCardContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gap: '10px',
});

const FileManagement = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<EmbeddingsFile[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  useEffect(() => {
    if (shouldFetch) {
      (async () => {
        const newFiles = await getEmbeddingsFiles();
        setFiles(newFiles);
        setLoading(false);
        setShouldFetch(false);
      })();
    }
  }, [shouldFetch]);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No file selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    const filesArray = Array.from(e.target.files);

    // Clear target value to allow the same filename
    // to be uploaded in succession.
    e.target.value = '';

    setLoading(true);

    for (const file of filesArray) {
      try {
        // Validate file size before uploading to server.
        const fileSizeMB = file.size / 1024 ** 2;
        if (fileSizeMB > 200) {
          enqueueSnackbar('Upload Failed. File size too large. Max size: 200MB.', {
            variant: 'error',
            autoHideDuration: 3000,
          });
          continue;
        }

        const formData = new FormData();
        formData.append('file', file);

        await postEmbeddingsFile(formData);
      } catch (e) {
        enqueueSnackbar(`Error processing document: ${file.name}.`, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } finally {
        setLoading(false);
        setShouldFetch(true);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <FileManagementDiv>
      <Typography sx={{ fontSize: '36px', fontWeight: '700', marginBottom: '20px' }}>
        File Management
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
          marginRight: '5px',
        }}
      >
        <Tooltip
          title={<Typography variant="caption"> Upload a document. 200MB limit. </Typography>}
        >
          <Button variant="contained" component="label" sx={{ bgcolor: '#1979CD', width: '300px' }}>
            <input type="file" multiple hidden onChange={handleFileUpload} accept=".pdf" />
            <Typography>{'Upload New File'}</Typography>
          </Button>
        </Tooltip>
      </Box>
      <FileCardContainer>
        {files.map((file, index) => (
          <FileCard
            key={index}
            file={file}
            onEdit={() => {
              setShouldFetch(true);
            }}
          />
        ))}
      </FileCardContainer>
    </FileManagementDiv>
  );
};

export default FileManagement;
