import styled from '@emotion/styled';
import {
  ContentCopy,
  DeleteOutlined,
  EditOutlined,
  Forward,
  ForwardOutlined,
  MoreVert,
  PushPin,
  PushPinOutlined,
  Timeline,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Box,
  CardContent,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PublishedImg from '../../assets/verified.svg';
import { deletePrompt, deletePromptAdmin } from '../../redux/actions/promptActions';
import { updateRelation } from '../../redux/actions/userPromptRelationsActions';
import { AppDispatch } from '../../redux/store';
import { StyledLink } from '../../theme/CustomComponents';
import { Tag } from '../../Types/enums';
import { Prompt } from '../../Types/prompt';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import { useAuth } from '../Providers/AuthProvider';
import { CopyDialog } from './CopyDialog';

const PromptCardComponent = styled('div')({
  width: '100%',
  height: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  },
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.01)',

  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  backgroundColor: '#fff',
  boxSizing: 'border-box',
  position: 'relative',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  height: '100%',
  padding: '10px',
  //overwriting the last child padding
  '&:last-child': {
    paddingBottom: '10px',
  },
});

interface PinContainerProps {
  pinned: boolean;
}

const PinContainer = styled('div')<PinContainerProps>(({ pinned }) => ({
  color: pinned ? '#0B6E27' : '#767676',
  border: pinned ? 'none' : '1px solid #767676',
  height: pinned ? '20px' : '18px',
  width: pinned ? '20px' : '18px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: pinned ? '#d6f5de' : '#fff',

  '& svg': {
    width: '14px',
    height: '14px',
  },

  ':hover': {
    cursor: 'pointer',
    backgroundColor: pinned ? '#9dd1ac' : '#d9d9d9',
  },
}));

const CardLeftSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  overflow: 'hidden',
  padding: '10px',
  flexGrow: 1,
  height: '100%',
});

const CardRightSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '10px',
  padding: '10px',
  minWidth: '120px',
  paddingRight: '2px',
});

interface LikeContainerProps {
  liked: boolean;
}

const LikeContainer = styled(IconButton)<LikeContainerProps>(({ liked }) => ({
  color: liked ? '#014361' : '#767676',
  border: liked ? '1px solid #014361' : '1px solid #767676',
  height: '18px',
  width: '18px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '5px',
  marginLeft: '10px',
  backgroundColor: liked ? '#cce6ff' : '#fff',

  '& svg': {
    width: '14px',
    height: '14px',
  },

  ':hover': {
    cursor: 'pointer',
    backgroundColor: liked ? '#95c4da' : '#d9d9d9',
  },
}));

const PromptCard = ({
  prompt,
  onPromptEdit,
  publishedOnly,
}: {
  prompt: Prompt;
  onPromptEdit: () => void;
  publishedOnly: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [pinTooltipOpen, setPinTooltipOpen] = useState<boolean>(false);
  const [copyOpen, setCopyOpen] = useState(false);

  const { currentUser, isAdmin } = useAuth();

  const iconSize: object = { height: '14px', width: '14px' };
  const iconStyle = {
    ...iconSize,
    color: 'gray',
  };

  const menuIconSize: object = { height: '18px', width: '16px' };
  const menuIconStyle = {
    ...menuIconSize,
    paddingRight: '10px',
  };
  const navigate = useNavigate();

  const handlePinned = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (currentUser) {
      setPinTooltipOpen(false);
      dispatch(
        updateRelation({ userId: currentUser.id, promptId: prompt.id, pinned: !prompt.pinned })
      ).then(() => {
        onPromptEdit();
      });
    }
  };

  const handleLiked = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (currentUser) {
      dispatch(
        updateRelation({ userId: currentUser.id, promptId: prompt.id, liked: !prompt.liked })
      ).then(() => {
        onPromptEdit();
      });
    }
  };

  const handleOpenChatContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleCopy = () => {
    setCopyOpen(true);
  };

  const handlePromptDelete = (promptId: string) => {
    if (isAdmin) {
      dispatch(deletePromptAdmin(promptId));
    } else {
      dispatch(deletePrompt(promptId));
    }
    onPromptEdit();
  };

  const handleEditPrompt = (prompt: Prompt) => {
    navigate(`/prompt-edit/${prompt.id}`);
  };

  const showTags = () => {
    const tags = [...prompt.tags].sort((a: Tag, b: Tag) => (a > b ? 1 : -1));

    return (
      <div
        id={prompt.id}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '4px',
          height: '100%',
        }}
      >
        <Chip
          variant="outlined"
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {prompt.published && !publishedOnly ? (
                <Tooltip title={<Typography variant="caption"> Published </Typography>}>
                  <img
                    style={{ marginRight: '5px', width: '14px', height: '14px' }}
                    src={PublishedImg}
                    alt={'Published'}
                  />
                </Tooltip>
              ) : null}
              Made by {currentUser?.id === prompt.createdBy ? 'me' : prompt.createdByName}
            </span>
          }
          sx={{ fontSize: '13px', fontWeight: '500', height: '24px' }}
        />
        {tags.map((tag: string, index: number) => (
          <Chip
            label={tag}
            key={index}
            sx={{ fontSize: '13px', fontWeight: '500', height: '24px' }}
          ></Chip>
        ))}
      </div>
    );
  };

  return (
    <PromptCardComponent onClick={() => navigate(`/chat?prompt=${prompt.id}`)}>
      <StyledCardContent>
        <CardLeftSection>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Tooltip
              title={<Typography variant="caption"> Pin this prompt </Typography>}
              open={pinTooltipOpen}
              onMouseEnter={() => setPinTooltipOpen(true)}
              onMouseLeave={() => setPinTooltipOpen(false)}
            >
              <PinContainer onClick={handlePinned} pinned={prompt.pinned || false}>
                {prompt.pinned ? <PushPin /> : <PushPinOutlined />}
              </PinContainer>
            </Tooltip>

            {prompt.featured && (
              <Box
                sx={{
                  backgroundColor: '#ffe9ca',
                  borderRadius: '4px',
                  color: '#d47202',
                  width: '85px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  alignItems: 'center',
                  marginLeft: '10px',
                }}
              >
                <Typography sx={{ fontSize: '13px', fontWeight: '500' }}>FEATURED</Typography>
              </Box>
            )}
          </div>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '16px',
            }}
          >
            <StyledLink to={`/chat?prompt=${prompt.id}`}>{prompt.title}</StyledLink>
          </Typography>
          <Typography
            id="description"
            sx={{
              height: '1.5rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {prompt.description}
          </Typography>
          {showTags()}
        </CardLeftSection>
        <CardRightSection>
          <div onClick={(e) => e.stopPropagation()}>
            <DeleteConfirmationDialog
              isOpen={deleteOpen}
              setIsOpen={setDeleteOpen}
              onDelete={() => handlePromptDelete(prompt.id)}
            />
            <Tooltip
              id={`action-menu-label-${prompt.id}`}
              title={<Typography variant="caption"> Actions </Typography>}
              PopperProps={{ keepMounted: true }}
            >
              <IconButton
                onClick={handleOpenChatContextMenu}
                sx={{ p: 0 }}
                aria-labelledby={`action-menu-label-${prompt.id}`}
              >
                <MoreVert sx={{ height: '20px', width: '20px' }} />
              </IconButton>
            </Tooltip>
            <CopyDialog
              open={copyOpen}
              onClose={() => {
                setCopyOpen(false);
              }}
              showShare={prompt.createdBy === currentUser?.id}
              promptId={prompt.id}
            />
            <Menu
              sx={{ mt: '32px' }}
              anchorEl={anchorContextMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorContextMenu)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCopy}>
                <ContentCopy sx={menuIconStyle} />
                <Typography>Copy Prompt</Typography>
              </MenuItem>
              {!isAdmin && prompt.createdBy !== currentUser?.id && (
                <MenuItem onClick={() => handleEditPrompt(prompt)}>
                  <VisibilityOutlined sx={menuIconStyle} />
                  <Typography>View</Typography>
                </MenuItem>
              )}
              {/* The reason there are two of these is because Menu expects MenuItem only as a child and JSX only allows one parent component (i.e. no <></> wrapper allowed)*/}
              {(isAdmin || prompt.createdBy == currentUser?.id) && (
                <MenuItem onClick={() => handleEditPrompt(prompt)}>
                  <EditOutlined sx={menuIconStyle} />
                  <Typography>Edit</Typography>
                </MenuItem>
              )}
              {(isAdmin || prompt.createdBy == currentUser?.id) && (
                <MenuItem onClick={() => setDeleteOpen(true)}>
                  <DeleteOutlined sx={menuIconStyle} />
                  <Typography>Delete</Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
          <Typography variant="caption" sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
              }}
            >
              <Tooltip title={<Typography variant="caption"> Prompt Usages </Typography>}>
                <Timeline
                  sx={{
                    ...iconStyle,
                    paddingRight: '5px',
                  }}
                />
              </Tooltip>
              {prompt.usageCount}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'gray',
                width: '50px',
              }}
            >
              <Tooltip
                id={`like-label-${prompt.id}`}
                PopperProps={{ keepMounted: true }}
                title={<Typography variant="caption"> Like this prompt </Typography>}
              >
                <LikeContainer
                  aria-labelledby={`like-label-${prompt.id}`}
                  onClick={handleLiked}
                  liked={prompt.liked || false}
                  style={{ transform: 'rotate(-90deg)' }}
                >
                  {prompt.liked ? <Forward /> : <ForwardOutlined />}
                </LikeContainer>
              </Tooltip>

              {prompt.likedCount || 0}
            </div>
          </Typography>
        </CardRightSection>
      </StyledCardContent>
    </PromptCardComponent>
  );
};

export default PromptCard;
