import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import { API_URL, enrichedFetch } from '../../services/api';
import { ProjectQuestionFeedback } from '../../Types/dealGPT';

export const updateFeedback = createAsyncThunk<
  void,
  { projectQuestionId: string; projectQuestionFeedback: ProjectQuestionFeedback }
>(
  'projectQuestion/updateFeedback',
  async ({ projectQuestionId, projectQuestionFeedback }, { rejectWithValue }) => {
    try {
      await enrichedFetch(
        `${API_URL}/projectQuestion/projectQuestionFeedback/${projectQuestionId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ projectQuestionFeedback }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch {
      enqueueSnackbar('Failed to update feedback', { variant: 'error' });
      return rejectWithValue('Failed to update feedback');
    }
  }
);
