import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import { API_URL, enrichedFetch } from '../../services/api';
import {
  Conversation,
  ConversationResponse,
  CreateConversation,
  CreateMessage,
  CreateMessagePrivate,
  DownloadConversationResponse,
  Message,
  MessageFeedback,
  MessageFile,
  UpdateConversation,
} from '../../Types/conversation';
import { Llm } from '../../Types/enums';
import { User } from '../../Types/user';
import { CONVERSATION_LIST_SIZE } from '../consts';
import { setCurrentMessages, setPage } from '../reducers/conversationReducer';
import { AppDispatch, RootState } from '../store';

export const getConversations = createAsyncThunk<
  ConversationResponse,
  { pageAction?: 'increment' | 'reset' } | void,
  { state: RootState }
>('conversations/fetchConversations', async (arg, { getState, dispatch, rejectWithValue }) => {
  const { page, searchQuery } = getState().conversations;

  const pageAction = arg?.pageAction;

  let newPage = page;
  if (pageAction === 'increment') {
    newPage += 1;
  } else if (pageAction === 'reset') {
    newPage = 1;
  }
  const pageOffset = CONVERSATION_LIST_SIZE * (newPage - 1);

  dispatch(setPage(newPage));

  try {
    let url = `${API_URL}/conversations?offset=${pageOffset}`;
    if (searchQuery.trim().length > 0) {
      url += `&q=${searchQuery}`;
    }
    const response = (await enrichedFetch(url)) as ConversationResponse;
    return response;
  } catch {
    enqueueSnackbar('Failed to fetch conversations', { variant: 'error' });
    return rejectWithValue('Failed to fetch conversations');
  }
});

export const getConversation = createAsyncThunk<Conversation, Conversation['id']>(
  'conversations/getConversation',
  async (id, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/conversations/${id}`)) as Conversation;
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch conversation', { variant: 'error' });
      return rejectWithValue('Failed to fetch conversation');
    }
  }
);

export const getSharedConversation = createAsyncThunk<Conversation, Conversation['id']>(
  'conversations/getSharedConversation',
  async (id, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(
        `${API_URL}/conversations/shared/${id}`
      )) as Conversation;
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch conversation', { variant: 'error' });
      return rejectWithValue('Failed to fetch conversation');
    }
  }
);

export const getStreamingResponse = createAsyncThunk<
  Response | unknown,
  { message: CreateMessage; userId: User['id']; llm: Llm; signal?: AbortSignal }
>('conversations/getStreamingResponse', async ({ message, userId, llm }, { rejectWithValue }) => {
  try {
    const result = await enrichedFetch(
      `${API_URL}/streaming`,
      {
        method: 'POST',
        body: JSON.stringify({ message, userId, llm }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      0,
      false
    );
    return result;
  } catch {
    enqueueSnackbar('Failed to fetch streaming response', { variant: 'error' });
    return rejectWithValue('Failed to fetch streaming response');
  }
});

export const getStreamingResponsePrivate = createAsyncThunk<
  Response | unknown,
  {
    message: CreateMessagePrivate;
    messageArray: Message[];
    userId: User['id'];
    llm: Llm;
    signal?: AbortSignal;
  }
>(
  'conversations/getStreamingResponsePrivate',
  async ({ message, messageArray, userId, llm, signal }, { rejectWithValue }) => {
    try {
      const result = await enrichedFetch(
        `${API_URL}/streaming/private`,
        {
          method: 'POST',
          body: JSON.stringify({ message, messageArray, userId, llm }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        0,
        false,
        signal
      );
      return result;
    } catch {
      enqueueSnackbar('Failed to fetch streaming response', { variant: 'error' });
      return rejectWithValue('Failed to fetch streaming response');
    }
  }
);

export const postConversation = createAsyncThunk<
  Conversation,
  CreateConversation,
  { dispatch: AppDispatch }
>('conversations/postConversation', async (conversation, { rejectWithValue }) => {
  try {
    const response = (await enrichedFetch(`${API_URL}/conversations`, {
      method: 'POST',
      body: JSON.stringify(conversation),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as Conversation;
    return response;
  } catch {
    enqueueSnackbar('Failed to post conversation', { variant: 'error' });
    return rejectWithValue('Failed to post conversation');
  }
});

export const postConvoFromMessage = createAsyncThunk<
  Conversation,
  Message,
  { dispatch: AppDispatch }
>('conversations/postConvoFromMessage', async (message, { dispatch, rejectWithValue }) => {
  try {
    const response = (await enrichedFetch(`${API_URL}/conversations/branch`, {
      method: 'POST',
      body: JSON.stringify(message),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as Conversation;

    dispatch(getConversations({ pageAction: 'reset' }));

    return response;
  } catch {
    enqueueSnackbar('Failed to post conversation from message', { variant: 'error' });
    return rejectWithValue('Failed to post conversation from message');
  }
});

export const regenerateMessage = createAsyncThunk<
  Conversation,
  { message: Message; llm: Llm },
  { dispatch: AppDispatch }
>('conversations/regenerateMessage', async ({ message, llm }, { dispatch, rejectWithValue }) => {
  try {
    const response = (await enrichedFetch(`${API_URL}/conversations/regenerate`, {
      method: 'POST',
      body: JSON.stringify({ message, llm }),
      headers: {
        'Content-Type': 'application/json',
      },
    })) as Conversation;

    dispatch(getConversations({ pageAction: 'reset' }));

    return response;
  } catch {
    enqueueSnackbar('Failed to regenerate message', { variant: 'error' });
    return rejectWithValue('Failed to regenerate message');
  }
});

export const updateConversation = createAsyncThunk<
  void,
  { updatedFields: UpdateConversation; conversationId: Conversation['id'] },
  { dispatch: AppDispatch }
>(
  'conversations/updateConversation',
  async ({ updatedFields, conversationId }, { rejectWithValue }) => {
    try {
      await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
        method: 'PUT',
        body: JSON.stringify(updatedFields),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch {
      enqueueSnackbar('Failed to update conversation', { variant: 'error' });
      return rejectWithValue('Failed to update conversation');
    }
  }
);

export const deleteConversation = createAsyncThunk<
  void,
  Conversation['id'],
  { dispatch: AppDispatch }
>('conversations/deleteConversation', async (conversationId, { rejectWithValue }) => {
  try {
    await enrichedFetch(`${API_URL}/conversations/${conversationId}`, {
      method: 'DELETE',
    });
  } catch {
    enqueueSnackbar('Failed to delete conversation', { variant: 'error' });
    return rejectWithValue('Failed to delete conversation');
  }
});

export const getMessages = createAsyncThunk<Message[], Conversation['id']>(
  'conversations/getMessages',
  async (conversationId, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(
        `${API_URL}/conversations/messages/${conversationId}`
      )) as Message[];
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch messages', { variant: 'error' });
      return rejectWithValue('Failed to fetch messages');
    }
  }
);

export const setCurrentMessagesAsync = createAsyncThunk(
  'conversations/setCurrentMessagesAsync',
  async (messages: Message[], { dispatch }) => {
    dispatch(setCurrentMessages(messages));
    return messages;
  }
);

export const postMessage = createAsyncThunk<
  Message,
  { message: CreateMessage; llm: string; gleanApplicationId?: string },
  { dispatch: AppDispatch }
>(
  'conversations/postMessage',
  async ({ message, llm, gleanApplicationId }, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/conversations/messages`, {
        method: 'POST',
        body: JSON.stringify({ message, llm, gleanApplicationId }),
        headers: {
          'Content-Type': 'application/json',
        },
      })) as Message;
      return response;
    } catch {
      enqueueSnackbar('Failed to post message', { variant: 'error' });
      return rejectWithValue('Failed to post message');
    }
  }
);

export const postMessagePrivate = createAsyncThunk<
  Message,
  {
    message: CreateMessagePrivate;
    messageArray: Message[];
    llm: string;
    gleanApplicationId?: string;
  }
>(
  'conversations/postMessagePrivate',
  async ({ message, messageArray, llm, gleanApplicationId }, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/conversations/messages/private`, {
        method: 'POST',
        body: JSON.stringify({ message, messageArray, llm, gleanApplicationId }),
        headers: {
          'Content-Type': 'application/json',
        },
      })) as Message;
      return response;
    } catch {
      enqueueSnackbar('Failed to post private message', { variant: 'error' });
      return rejectWithValue('Failed to post private message');
    }
  }
);

export const postFile = createAsyncThunk<MessageFile, FormData>(
  'conversations/postFile',
  async (formData, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/conversations/messages/file/upload`, {
        method: 'POST',
        body: formData,
      })) as MessageFile;
      return response;
    } catch {
      enqueueSnackbar('Failed to post file', { variant: 'error' });
      return rejectWithValue('Failed to post file');
    }
  }
);

export const downloadFile = createAsyncThunk<DownloadConversationResponse, Conversation['id']>(
  'conversations/downloadFile',
  async (conversationId, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/conversations/download/${conversationId}`, {
        method: 'GET',
      })) as DownloadConversationResponse;
      return response;
    } catch {
      enqueueSnackbar('Failed to download file', { variant: 'error' });
      return rejectWithValue('Failed to download file');
    }
  }
);

export const updateFeedback = createAsyncThunk<
  void,
  { messageId: Message['id']; messageFeedback: MessageFeedback }
>('conversations/updateFeedback', async ({ messageId, messageFeedback }, { rejectWithValue }) => {
  try {
    await enrichedFetch(`${API_URL}/conversations/messages/messageFeedback/${messageId}`, {
      method: 'PUT',
      body: JSON.stringify({ messageFeedback }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch {
    enqueueSnackbar('Failed to update feedback', { variant: 'error' });
    return rejectWithValue('Failed to update feedback');
  }
});
