import { enqueueSnackbar } from 'notistack';

import { StatusError } from '../components/Providers/ErrorProvider';
import { User, UserWithToken } from '../Types/user';

export const API_URL = import.meta.env.VITE_APP_API_HOST;

let setErrorFunction: ((error: StatusError) => Promise<boolean>) | null;
setErrorFunction = null;

export const registerErrorFunction = (fn: (error: StatusError) => Promise<boolean>) => {
  setErrorFunction = fn;
};

export const enrichedFetch = async (
  url: string,
  options = {} as RequestInit,
  retryTimes = 0,
  responseJson = true,
  signal?: AbortSignal
): Promise<unknown> => {
  const handleError = async (error: string, status: number) => {
    if (setErrorFunction) {
      return setErrorFunction(new StatusError(error, status));
    } else {
      console.error(error);
    }
  };

  const headers = new Headers(options.headers);
  if (localStorage.getItem('token')) {
    headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  }

  if (localStorage.getItem('graphToken')) {
    headers.set('GraphToken', `Bearer ${localStorage.getItem('graphToken')}`);
  }
  headers.set('Timezone', `${Intl.DateTimeFormat().resolvedOptions().timeZone}`);

  if (localStorage.getItem('jwt')) {
    headers.set('jwt', `${localStorage.getItem('jwt')}`);
  }

  options.headers = headers;
  if (signal) {
    options.signal = signal;
  }

  let response = null;
  try {
    response = await fetch(url, { ...options, credentials: 'include', mode: 'cors' });
  } catch (error) {
    const errorMessage = 'Failed to connect to server';
    handleError(errorMessage, 0);
    throw new StatusError(errorMessage, 0);
  }

  if (response.status === 204) return response;

  if (response.status === 401) {
    const error = 'Unauthorized';
    const handler = await handleError(error, response.status);
    if (handler && retryTimes < 1) {
      console.log('User session was stale, retrying');
      return enrichedFetch(url, options, 1);
    } else {
      console.log('User refresh failed, logging out');
      throw new Error(error);
    }
  }

  if (response.status < 200 || response.status >= 300) {
    const responseMessage = (await response.json()).message;

    if (
      responseMessage.startsWith("This model's maximum context") ||
      responseMessage === 'context_length_exceeded'
    ) {
      enqueueSnackbar(
        "Input Exceeded Model's Context Limit - Please try shortening the message or creating a new conversation",
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );

      throw new Error("Input Exceeded Model's Context Limit");
    } else if (responseMessage === 'content_filter') {
      enqueueSnackbar('Content Filter Triggered', {
        variant: 'error',
        autoHideDuration: 3000,
      });

      throw new Error('Content Filter Triggered');
    }
  } else {
    if (responseJson) return response.json();
  }
  return response;
};

export const login = (email?: string, password?: string): Promise<User> => {
  const options: RequestInit = {
    method: 'GET',
  };

  if (email && password) {
    options.body = JSON.stringify({
      email,
      password,
    });
    options.method = 'POST';
    options.headers = {
      'Content-Type': 'application/json',
    };
  }
  return enrichedFetch(`${API_URL}/user/login`, options) as Promise<User>;
};

export const loginAzureAD = (): Promise<UserWithToken> => {
  return enrichedFetch(`${API_URL}/user/login/azuread`) as Promise<UserWithToken>;
};

export const logoutUser = (): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/logout`, {
    method: 'POST',
  }) as Promise<User>;
};

export const getAzureGraphToken = (): Promise<string> => {
  return enrichedFetch(`${API_URL}/user/graphToken`) as Promise<string>;
};
