/**
 * theme.ts
 *
 * This file is responsible for creating the Material-UI theme for the application.
 * It imports the design tokens defined in designTokens.ts and uses them to define
 * the color palette and component styles for the MUI theme.
 *
 * The theme object is then provided to the entire application using the ThemeProvider
 * component from MUI, ensuring consistent styling across all components.
 */

import './themeAugmentation';

import { createTheme } from '@mui/material';

import { designTokens } from './designTokens';

const theme = createTheme({
  palette: {
    base: designTokens.base,
    utility: designTokens.utility,
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    h1: {
      fontSize: '56px',
      lineHeight: '62px',
      letterSpacing: '0.01px',
      fontWeight: 800,
    },
    h2: {
      fontSize: '42px',
      lineHeight: '52px',
      letterSpacing: '0.01px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '36px',
      lineHeight: '46px',
      letterSpacing: '0.01px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '36px',
      lineHeight: '46px',
      letterSpacing: '0.01px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '0.01px',
      fontWeight: 800,
    },
    subtitle1: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.01px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.01px',
      fontWeight: 700,
    },
    body1: {
      fontSize: '16px',
      // Commenting this out for now to maintain the current
      // look of Nigel. The MUI body1 lineHeight default
      // seems to be 3, so 1.5 would bring lines closer together
      // lineHeight: '1.5rem',
      letterSpacing: '0.01px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.01px',
      fontWeight: 400,
    },
    button: {
      fontSize: '14px',
      // Commenting this out for now to maintain the current
      // look of Nigel. The MUI Button lineHeight default
      // seems to be 1.6, so 0.875 would bring lines closer together
      // lineHeight: '0.875rem',
      letterSpacing: '0.01px',
      fontWeight: 500,
      textTransform: 'none',
    },
    caption1: {
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '-0.2px',
      fontWeight: 400,
    },
    caption2: {
      fontSize: '11px',
      lineHeight: '11px',
      letterSpacing: '-0.2px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0px',
        },
        contained: {
          backgroundColor: '#092b49',
          '&:hover': {
            backgroundColor: '#135996',
          },
          '&:active': {
            backgroundColor: '#1C86E3',
          },
        },
        outlined: {
          color: '#092b49',
          borderColor: '#092b49',

          ':hover': {
            color: '#135996',
            borderColor: '#135996',
          },

          ':active': {
            color: '#1C86E3',
            borderColor: '#1C86E3',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',

          '& > *': {
            fontFamily: 'Plus Jakarta Sans',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginRight: 'auto',
        },
      },
    },
  },
});

export default theme;
