import styled from '@emotion/styled';
import { ExpandMore, HelpOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Chip,
  Container,
  Grid,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { BubbleMenu, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { FormApi } from 'final-form';
import { Switches, TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import ModificationConfirmationDialog from '../components/Prompts/ModificationConfirmationDialog';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import {
  createPrompt,
  createPromptAdmin,
  findPrompt,
  updatePrompt,
  updatePromptAdmin,
} from '../redux/actions/promptActions';
import { PROMPT_DEFAULTS } from '../redux/consts';
import { AppDispatch } from '../redux/store';
import {
  drawerWidth,
  MainContainer,
  PromptEditHelperText,
  PromptEditTiptapLabel,
  PromptEditTiptapStyled,
  PromptEditTiptapWrapper,
  StyledGridContainer,
} from '../theme/CustomComponents';
import { AppEnum, Tag, ToolName } from '../Types/enums';
import { CreatePrompt, Prompt, UpdatePrompt } from '../Types/prompt';
import { useIsMobile } from '../util/useIsMobile';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
});

const PromptContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,
  background: 'white',
  height: '100%',
  overflowY: 'auto',
}));

const StyledForm = styled('form')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '0 20px 20px 20px',
});

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  marginBottom: '10px',
});

const StyledSubmitButton = styled(Button)(() => {
  const isMobile = useIsMobile();
  return {
    width: isMobile ? '50%' : '25%',
    margin: '16px',
  };
});

const PromptEdit = () => {
  const { promptId } = useParams();
  const navigate = useNavigate();

  const { currentUser, isAdmin } = useAuth();
  const features = useFeatures();
  const dispatch = useDispatch<AppDispatch>();

  const shouldSaveCopyRef = useRef(false);
  const [isModificationConfirmationOpen, setIsModificationConfirmationOpen] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState<CreatePrompt>(PROMPT_DEFAULTS);
  const [originalPrompt, setOriginalPrompt] = useState<Prompt>();
  const [showFeatured, setShowFeatured] = useState(currentPrompt?.published);
  const [modalTile, setModalTitle] = useState<string>(
    'Are you sure you want to update this published prompt?'
  );

  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );
  const [editingIsRequired, setEditingIsRequired] = useState(false);
  const [selectedText, setSelectedText] = useState<string[]>([]);
  const handleDeleteChip = (index: number) => {
    setSelectedText((prevChips) => prevChips.filter((_, i) => i !== index));
  };

  const editor = useEditor({
    extensions: [StarterKit, TextStyle, Color],
    shouldRerenderOnTransaction: false,
  });

  const addSelectedVariable = () => {
    const selection = window.getSelection();

    if (selection && editingIsRequired) {
      const textVar = selection.toString().trim();

      const isWholeWord = (text: string, fullText: string | undefined) => {
        if (!fullText) return false;
        const startIndex = fullText.indexOf(text);
        const endIndex = startIndex + text.length;

        // Check if a character is a punctuation mark
        const isPunctuation = (char: string) => {
          return /[.,!?;:]/.test(char);
        };

        // Check if the selection is at the start, end, or surrounded by spaces or punctuation
        const isAtStart = startIndex === 0;
        const isAtEnd = endIndex === fullText.length;
        const isSurroundedBySpacesOrPunctuation =
          startIndex > 0 &&
          (fullText[startIndex - 1] === ' ' || isPunctuation(fullText[startIndex - 1])) &&
          endIndex < fullText.length &&
          (fullText[endIndex] === ' ' || isPunctuation(fullText[endIndex]));

        return isAtStart || isAtEnd || isSurroundedBySpacesOrPunctuation;
      };

      const textVarLower = textVar.toLowerCase();
      const selectedTextLower = selectedText.map((text) => text.toLowerCase());

      if (
        textVar &&
        editor &&
        isWholeWord(textVar, editor.getText().trim()) &&
        !selectedTextLower.includes(textVarLower)
      ) {
        setSelectedText((prevSelectedText) => [...prevSelectedText, textVar]);
        window.getSelection()?.removeAllRanges();
        editor.chain().selectAll().run();
      }
    }

    if (editor) {
      const { from } = editor.state.selection;
      editor.chain().setTextSelection(from).run();
    }
  };

  useEffect(() => {
    if (!editingIsRequired) {
      setSelectedText([]);
    }
  }, [editingIsRequired]);

  const setEditorContent = useCallback(
    (content: string) => {
      if (editor) {
        editor.commands.setContent(content);
      }
    },
    [editor]
  );

  useEffect(() => {
    setShowFeatured(currentPrompt?.published);
  }, [currentPrompt?.published]);

  useEffect(() => {
    if (promptId) {
      const fetchPrompt = () => {
        dispatch(findPrompt(promptId)).then((action: PayloadAction<Prompt | unknown>) => {
          const newPrompt = action.payload as Prompt;
          setCurrentPrompt(newPrompt);
          setOriginalPrompt(newPrompt);
          setSelectedText(newPrompt.variables);
          setEditingIsRequired(newPrompt.userEditRequired);
          setEditorContent(newPrompt.prompt);
        });
      };
      fetchPrompt();
    }
  }, [promptId, dispatch, setEditorContent]);

  const handleConfirm = (passedPrompt?: CreatePrompt) => {
    let newPrompt = { ...originalPrompt };

    if (passedPrompt) {
      newPrompt = { ...originalPrompt, ...passedPrompt };
    } else {
      newPrompt = { ...originalPrompt, ...currentPrompt };
    }

    if (!isAdmin) {
      if ('featured' in newPrompt) delete newPrompt.featured;
    }

    if ('id' in newPrompt) delete newPrompt.id;
    if ('createdAt' in newPrompt) delete newPrompt.createdAt;
    if ('createdBy' in newPrompt) delete newPrompt.createdBy;
    if ('updatedBy' in newPrompt) delete newPrompt.updatedBy;
    if ('deleted' in newPrompt) delete newPrompt.deleted;
    if ('fullCount' in newPrompt) delete newPrompt.fullCount;

    if (!shouldSaveCopyRef.current && promptId) {
      if (isAdmin) {
        dispatch(updatePromptAdmin({ prompt: newPrompt as UpdatePrompt, promptId }));
      } else {
        dispatch(updatePrompt({ prompt: newPrompt as UpdatePrompt, promptId }));
      }
    } else {
      if (isAdmin) {
        dispatch(createPromptAdmin(newPrompt as CreatePrompt));
      } else {
        dispatch(createPrompt(newPrompt as CreatePrompt));
      }
    }

    enqueueSnackbar('Prompt saved!', { variant: 'success' });
    navigate('/my-prompts');
  };

  const onSubmit = (values: CreatePrompt) => {
    const newPrompt = {
      title: shouldSaveCopyRef.current ? values['title'] + ' Copy' : values['title'],
      description: values['description'],
      prompt: editor?.getText().trim() ?? '',
      published: shouldSaveCopyRef.current ? false : values['published'],
      userEditRequired: values['userEditRequired'],
      metadata: values['metadata'],
      tags: values['tags'],
      tools: values['tools'],
      appName: values['appName'],
      featured: shouldSaveCopyRef.current ? false : values['featured'],
      variables: selectedText,
    } as CreatePrompt;
    setCurrentPrompt(newPrompt);

    if (
      (currentPrompt?.published && !shouldSaveCopyRef.current) ||
      (!currentPrompt?.published && newPrompt.published)
    ) {
      if ((currentPrompt?.published === false || !prompt) && newPrompt.published) {
        setModalTitle('Are you sure you want to publish this prompt?');
      } else {
        setModalTitle('Are you sure you want to update this published prompt?');
      }
      setIsModificationConfirmationOpen(true);
    } else {
      handleConfirm(newPrompt);
    }
  };

  const shouldShowFeatured = (form: FormApi<CreatePrompt, Partial<CreatePrompt>>) => {
    if (showFeatured) {
      form.change('featured', false);
      setShowFeatured(false);
    } else {
      setShowFeatured(true);
    }
  };

  const isUserAllowedToEditPrompt = () => {
    return originalPrompt?.createdBy === currentUser?.id || promptId == null || isAdmin;
  };

  return (
    <MainDiv>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={filterDrawerOpen}
        setDrawerOpen={setFilterDrawerOpen}
        showNewChatButton={false}
        showConversationList={false}
      />
      <MainContainer open={filterDrawerOpen}>
        <ModificationConfirmationDialog
          onConfirm={() => handleConfirm()}
          onCancel={() => {
            setIsModificationConfirmationOpen(false);
          }}
          isOpen={isModificationConfirmationOpen}
          setIsOpen={setIsModificationConfirmationOpen}
          title={modalTile}
          content="Everyone will be able to see your changes. Unlike chats, prompts are public."
        />

        <NavBar mode={NavBarMode.NIGEL} />
        <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
          <PromptContainer>
            <Container maxWidth="md">
              <FinalForm
                initialValues={{ ...PROMPT_DEFAULTS, ...currentPrompt }}
                onSubmit={onSubmit}
                render={({ handleSubmit, form, values }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: '700',
                        fontSize: '20px',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      {promptId ? 'Edit Prompt' : 'New Prompt'}
                    </Typography>
                    <TextField
                      label="Title"
                      name={'title'}
                      helperText="ex: Generate questions from notes..."
                      required
                      InputLabelProps={{ shrink: true }}
                      disabled={!isUserAllowedToEditPrompt()}
                    />
                    <TextField
                      label="Description"
                      name={'description'}
                      helperText="ex: Use this prompt to generate notes..."
                      required
                      multiline
                      minRows={2}
                      InputLabelProps={{ shrink: true }}
                      disabled={!isUserAllowedToEditPrompt()}
                    />
                    <PromptEditTiptapWrapper>
                      <PromptEditTiptapLabel>Prompt *</PromptEditTiptapLabel>
                      <PromptEditTiptapStyled editor={editor} />
                    </PromptEditTiptapWrapper>
                    <PromptEditHelperText>
                      ex: Generate a bulleted list with the most important action items from these
                      notes...
                    </PromptEditHelperText>
                    {editor && (
                      <BubbleMenu editor={editingIsRequired ? editor : null}>
                        <button
                          type="button"
                          style={{
                            fontFamily: 'Noto Sans',
                            fontSize: '15px',
                            padding: '5px',
                            margin: '5px',
                          }}
                          onClick={() => {
                            addSelectedVariable();
                          }}
                        >
                          Create Variable
                        </button>
                      </BubbleMenu>
                    )}
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      disabled={!isUserAllowedToEditPrompt()}
                      options={Object.values(Tag)}
                      value={values.tags}
                      onChange={(_, newValue) => {
                        form.change('tags', newValue);
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        const inputValue = (e.target as HTMLInputElement).value.trim() as Tag;
                        if (
                          e.key === 'Tab' &&
                          inputValue &&
                          !values.tags.includes(inputValue) &&
                          Object.values(Tag).includes(inputValue)
                        ) {
                          e.preventDefault();
                          form.change('tags', [...values.tags, inputValue]);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          inputProps={{
                            ...params.inputProps,
                            required: values['tags'].length === 0,
                            onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                              const inputValue = (e.target as HTMLInputElement).value.trim() as Tag;
                              if (e.key === 'Enter' && !Object.values(Tag).includes(inputValue)) {
                                e.preventDefault();
                              }
                            },
                          }}
                          name="tagInput"
                          variant="standard"
                          label="Tags"
                        />
                      )}
                    />
                    <p
                      style={{
                        fontSize: '0.75rem',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontWeight: '400',
                        letterSpacing: '0.03333em',
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        marginLeft: '14px',
                        lineHeight: '1.66',
                        marginTop: '0',
                        marginBottom: '0',
                      }}
                    >
                      Be sure to tag with the appropriate model if needed. You need to select at
                      least one tag.
                    </p>
                    <>
                      {(features.show_tools || isAdmin) && (
                        <Autocomplete
                          multiple
                          disabled={!isUserAllowedToEditPrompt()}
                          id="tools-standard"
                          options={Object.values(ToolName)}
                          value={values.tools}
                          onChange={(_, newValue) => {
                            form.change('tools', newValue);
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                            const inputValue = (
                              e.target as HTMLInputElement
                            ).value.trim() as ToolName;
                            if (
                              e.key === 'Tab' &&
                              inputValue &&
                              !values.tools.includes(inputValue) &&
                              Object.values(ToolName).includes(inputValue)
                            ) {
                              e.preventDefault();
                              form.change('tools', [...values.tools, inputValue]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="toolInput"
                              variant="standard"
                              label="Tools"
                            />
                          )}
                        />
                      )}
                    </>
                    <>
                      {(features.app_employee_handbook_access || features.app_bia_access) && (
                        <Autocomplete
                          disableClearable
                          disabled={!isUserAllowedToEditPrompt()}
                          id="appName-standard"
                          options={Object.values(AppEnum)}
                          value={values.appName}
                          onChange={(_, newValue) => {
                            form.change('appName', newValue ?? undefined);
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                            const inputValue = (
                              e.target as HTMLInputElement
                            ).value.trim() as AppEnum;
                            if (
                              e.key === 'Tab' &&
                              inputValue &&
                              !values.appName.includes(inputValue) &&
                              Object.values(AppEnum).includes(inputValue)
                            ) {
                              e.preventDefault();
                              form.change('appName', inputValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="appNameInput"
                              variant="standard"
                              label="App to Use"
                            />
                          )}
                        />
                      )}
                    </>
                    <Switches
                      disabled={!isUserAllowedToEditPrompt()}
                      helperText={`If toggled on, the prompt text will appear in the message field to allow editing before sending. If toggled off, the prompt will automatically be triggered to send once the user clicks the prompt card.
                        Highlight text with your mouse in the prompt field to add variables to the prompt.`}
                      name={'userEditRequired'}
                      data={{ label: 'User Edit Required?', value: false }}
                      onClick={() => setEditingIsRequired(!editingIsRequired)}
                    />
                    {selectedText.length > 0 && (
                      <div style={{ marginTop: '10px', marginLeft: '14px' }}>
                        <h4>Prompt Variables</h4>
                        {selectedText.map((chip, index) => (
                          <Chip
                            key={index}
                            label={chip}
                            onDelete={() => handleDeleteChip(index)}
                            style={{ margin: '2px' }}
                          />
                        ))}
                      </div>
                    )}
                    <Switches
                      disabled={!isUserAllowedToEditPrompt()}
                      helperText={
                        'This controls whether or not the prompt is published for communal use'
                      }
                      name={'published'}
                      onClick={() => shouldShowFeatured(form)}
                      data={{ label: 'Publish this prompt?', value: false }}
                    />
                    {isAdmin && showFeatured && (
                      <>
                        <Switches
                          helperText={
                            'This controls whether or not the prompt is featured for communal use'
                          }
                          name={'featured'}
                          data={{ label: 'Make this prompt featured?', value: false }}
                        />
                      </>
                    )}
                    <StyledAccordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel-content"
                        id="panel-header"
                      >
                        <Typography>Advanced Settings</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ display: 'flex' }}>
                          <Typography id={'temperature-label'} sx={{ marginRight: '10px' }}>
                            Temperature
                          </Typography>
                          <Tooltip
                            id={'temperature-description'}
                            title={
                              <Typography variant="caption">
                                Temperature determines how analytical (lower) or creative (higher)
                                you want the response to be. 0 is good for an objective response and
                                0.7-0.8 is good for a creative response.
                              </Typography>
                            }
                          >
                            <HelpOutline />
                          </Tooltip>
                        </div>
                        <StyledGridContainer container={true}>
                          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            🤖
                          </Grid>
                          <Grid item xs={10}>
                            <Field name="metadata.temperature">
                              {({ input }) => (
                                <Slider
                                  aria-labelledby={'temperature-label'}
                                  aria-describedby={'temperature-description'}
                                  min={0}
                                  max={1}
                                  step={0.1}
                                  marks
                                  disabled={!isUserAllowedToEditPrompt()}
                                  valueLabelDisplay="auto"
                                  value={input.value}
                                  onChange={(_, newValue) => input.onChange(newValue)}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            🍄
                          </Grid>
                        </StyledGridContainer>
                      </AccordionDetails>
                    </StyledAccordion>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {promptId && (
                        <StyledSubmitButton
                          type="submit"
                          onClick={() => {
                            shouldSaveCopyRef.current = true;
                          }}
                          variant="outlined"
                          color="success"
                        >
                          Copy To My Prompts
                        </StyledSubmitButton>
                      )}

                      {isUserAllowedToEditPrompt() && (
                        <StyledSubmitButton
                          id="save-button"
                          type="submit"
                          disabled={!isUserAllowedToEditPrompt()}
                          onClick={() => {
                            shouldSaveCopyRef.current = false;
                          }}
                          variant="contained"
                        >
                          Save
                        </StyledSubmitButton>
                      )}
                    </div>
                  </StyledForm>
                )}
              />
            </Container>
          </PromptContainer>
        </div>
        <div style={{ height: '20px' }}></div>
      </MainContainer>
    </MainDiv>
  );
};

export default PromptEdit;
