import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

import { fetchCategoryQuestions } from '../../services/dealGPT';
import type { CategoryType, QuestionTypeTreeNode } from '../../Types/dealGPT';
import { DealGPTQuestionStatus } from '../../Types/enums';
import CategoryView from './CategoryView';
import StatusProgressBar from './StatusProgressBar';

const CategoryAccordionContainer = styled(Accordion)({
  borderBottom: `1px solid #C4C4C4`,

  '&:last-child': {
    borderBottom: 0,
  },
});

const CategoryAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '24px',
  },
  '& .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: '24px',
  },
});

const LoadingOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
});

export type CategoryAccordionProps = {
  projectId: string;
  category: CategoryType;
  expandAll: boolean;
  runId: string;
  latestRunId: string;
  projectRunStatus: string;
  gleanAppId: string;
  selectedStatusFilters: DealGPTQuestionStatus[];
  searchQuery: string;
};

const CategoryAccordion = ({
  projectId,
  category,
  expandAll,
  runId,
  latestRunId,
  projectRunStatus,
  gleanAppId,
  selectedStatusFilters,
  searchQuery,
}: CategoryAccordionProps): JSX.Element => {
  const [categoryQuestions, setCategoryQuestions] = useState<QuestionTypeTreeNode[]>([]);
  const [expandCategory, setExpandCategory] = useState<boolean>(expandAll);
  const [questionsLoaded, setQuestionsLoaded] = useState<boolean>(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleOpenCloseAccordion = () => {
    setExpandCategory(!expandCategory);
  };

  useEffect(() => {
    setQuestionsLoaded(false);
  }, [selectedStatusFilters, searchQuery]);

  useEffect(() => {
    const getCategoryQuestions = async () => {
      setIsLoadingQuestions(true);

      const newCategoryQuestions = await fetchCategoryQuestions(
        projectId,
        category.scope,
        category.name,
        selectedStatusFilters,
        searchQuery,
        runId
      );

      if (newCategoryQuestions._isSuccess && newCategoryQuestions._data) {
        setCategoryQuestions(newCategoryQuestions._data);
        setQuestionsLoaded(true);
      } else {
        enqueueSnackbar(`Failed to fetch category questions: ${newCategoryQuestions._error}`, {
          variant: 'error',
        });
      }

      setIsLoadingQuestions(false);

      setQuestionsLoaded(true);
    };

    if (!questionsLoaded && expandCategory) {
      setIsLoadingQuestions(true);
      getCategoryQuestions();
    }
  }, [
    projectId,
    category,
    runId,
    questionsLoaded,
    expandCategory,
    selectedStatusFilters,
    searchQuery,
  ]);

  // Contains a timer to update questions when a run is selected that is in progress.
  useEffect(() => {
    async function updateCategoryQuestions() {
      const updatedQuestions = await fetchCategoryQuestions(
        projectId,
        category.scope,
        category.name,
        selectedStatusFilters,
        searchQuery,
        runId
      );

      if (updatedQuestions._isSuccess && updatedQuestions._data) {
        setCategoryQuestions(updatedQuestions._data);
      }
    }

    if (runId === latestRunId && categoryQuestions.find((q) => q.status === 'PENDING')) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      updateCategoryQuestions();
      intervalRef.current = setInterval(updateCategoryQuestions, 5000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = null;
    };
  }, [
    runId,
    latestRunId,
    projectRunStatus,
    projectId,
    category.scope,
    category.name,
    categoryQuestions,
    selectedStatusFilters,
    searchQuery,
  ]);

  useEffect(() => {
    setExpandCategory(expandAll);
  }, [expandAll]);

  useEffect(() => {
    setExpandCategory(false);
    setQuestionsLoaded(false);
  }, [runId]);

  return (
    <CategoryAccordionContainer
      square
      disableGutters
      expanded={expandCategory}
      onChange={handleOpenCloseAccordion}
      id={`${category.scope}-${category.name}`}
    >
      <CategoryAccordionSummary expandIcon={<ChevronRightSharpIcon />}>
        <div>
          <Typography variant="caption1" noWrap sx={{ lineHeight: '16px' }}>
            {category.scope}
          </Typography>
          <Typography variant="subtitle2" sx={{ marginTop: '8px' }}>
            {category.name}
          </Typography>
          <Typography sx={{ fontStyle: 'italic' }}>{category.quantity} questions</Typography>
        </div>
        <div style={{ width: '33%', height: '20px', marginTop: '8px' }}>
          <StatusProgressBar
            quantity={category.quantity}
            quantityAnswered={category.quantityAnswered}
            quantityMoreInfoNeeded={category.quantityMoreInfoNeeded}
            quantityNotFound={category.quantityNotFound}
            quantitySkipped={category.quantitySkipped}
          />
        </div>
      </CategoryAccordionSummary>
      <AccordionDetails style={{ position: 'relative' }}>
        {isLoadingQuestions ? (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        ) : (
          <CategoryView
            gleanAppId={gleanAppId}
            setQuestions={setCategoryQuestions}
            questions={categoryQuestions}
            expandAll={expandAll}
          />
        )}
      </AccordionDetails>
    </CategoryAccordionContainer>
  );
};

export default CategoryAccordion;
