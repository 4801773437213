import { CardContent, Chip, styled, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import { setCurrentAssistant } from '../../redux/reducers/assistantsReducer';
import { Assistant } from '../../Types/AIAccountCoach/accountCoach';

const AssistantCardContainer = styled('div')({
  height: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.10)',
  },
  borderRadius: '12px',
  boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',

  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  backgroundColor: '#fdfdfd',
  boxSizing: 'border-box',
  position: 'relative',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  width: '500px',
  height: '150px',
});

const AssistantCard = ({ assistant }: { assistant: Assistant }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleAssistantClick = () => {
    dispatch(setCurrentAssistant(assistant));
    navigate(`/assistants/${assistant.id}`);
  };

  return (
    <AssistantCardContainer onClick={handleAssistantClick}>
      <StyledCardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '16px',
            }}
          >
            {assistant.name}
          </Typography>

          {assistant.category && (
            <div style={{ height: '24px' }}>
              <Chip
                label={assistant.category}
                sx={{
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              />
            </div>
          )}
        </div>
        <Typography
          variant="body1"
          sx={{
            height: '1.5rem',
            whiteSpace: 'wrap',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {assistant.description}
        </Typography>
      </StyledCardContent>
    </AssistantCardContainer>
  );
};

export default AssistantCard;
