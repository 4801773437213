import { combineReducers } from 'redux';

import appStatusReducer from './appStatusReducer';
import assistantsReducer from './assistantsReducer';
import conversationReducer from './conversationReducer';
import drawerOpenReducer from './drawerOpenReducer';
import promptReducer from './promptReducer';

export default combineReducers({
  appStatus: appStatusReducer,
  assistants: assistantsReducer,
  conversations: conversationReducer,
  drawer: drawerOpenReducer,
  prompts: promptReducer,
});
