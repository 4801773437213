import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '0',
  paddingBottom: '10px',
});

const FeatureHighlights: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: 'gray',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NEW FEATURE HIGHLIGHTS
      </Typography>
      <div style={{ height: 'inherit' }}>
        <List sx={{ height: 'inherit', marginTop: '10px', paddingTop: '0', paddingBottom: '0' }}>
          <StyledListItem>
            <Typography variant="body2">
              <b>🛑 Cancel Message Generation</b>
            </Typography>
            <Typography variant="body2">
              Use the red square in the message box to stop a response, allowing for real-time
              conversation flexibility.
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <b>♻️ Reuse Prompts and Share Conversations</b>
            </Typography>
            <Typography variant="body2">
              Save time with quick access to past prompts or share conversations with colleagues
              using the ellipses in your conversation box.
            </Typography>
          </StyledListItem>
        </List>
      </div>
    </>
  );
};

export default FeatureHighlights;
