import { Button, List, ListItem, styled } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConversations } from '../../redux/actions/conversationActions';
import {
  selectConversations,
  setConversationSearchSelected,
  setSearchQuery,
} from '../../redux/reducers/conversationReducer';
import { AppDispatch } from '../../redux/store';
import ChatCard from '../Chat/ChatCard';
import DebouncedSearchInput from '../DebouncedSearchInput';
import { useFeatures } from '../Providers/FeatureProvider';

const ListContainer = styled('div')({
  marginTop: '16px',
  paddingTop: '8px',
});

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '100%',
  justifyContent: 'flex-start',
  gap: '16px',
  marginTop: '16px',
  paddingTop: 0,
});

const LoadMoreButton = styled(Button)({
  margin: '8px 16px',
  color: 'black',
  fontWeight: 'bold',
});

interface ConversationListProps {
  onConversationUpdated: (
    conversationId?: string,
    projectId?: string,
    shouldFetchConversations?: boolean
  ) => void;
  loadMoreConversations: () => void;
}

const ConversationList = ({
  loadMoreConversations,
  onConversationUpdated,
}: ConversationListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { conversations, searchQuery, totalCount, conversationSearchSelected } =
    useSelector(selectConversations);
  const features = useFeatures();

  const shouldShowLoadMoreButton = conversations.length > 0 && conversations.length < totalCount;

  return (
    <ListContainer>
      {features.conversation_search && (
        <DebouncedSearchInput
          value={searchQuery}
          valueUpdated={(value) => {
            dispatch(setSearchQuery(value));
            dispatch(getConversations({ pageAction: 'reset' }));
          }}
          onFocus={() => dispatch(setConversationSearchSelected(true))}
          onBlur={() => dispatch(setConversationSearchSelected(false))}
          shouldFocus={conversationSearchSelected}
        />
      )}
      <StyledList>
        {conversations.map((conversation) => (
          <ListItem key={conversation.id} disablePadding>
            <ChatCard
              IsSearchFocused={conversationSearchSelected}
              conversation={conversation}
              onConversationCardClick={onConversationUpdated}
            />
          </ListItem>
        ))}
      </StyledList>
      {shouldShowLoadMoreButton && (
        <LoadMoreButton onClick={loadMoreConversations}>Load More +</LoadMoreButton>
      )}
    </ListContainer>
  );
};

export default ConversationList;
