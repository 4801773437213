import { Send, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { Box, Chip, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { FeedbackContext, FeedbackOptionsByContext } from '../constants/feedbackConstants';
import { ChatRow, IconContainer, IconsContainer, SendButton } from '../theme/CustomComponents';
import { FeedbackOptions } from '../Types/enums';
import { FeedbackInterface } from '../Types/feedback';

export type FeedbackProps = {
  initialFeedback?: FeedbackInterface;
  context: (typeof FeedbackContext)[keyof typeof FeedbackContext];
  onFeedbackSubmit: (feedback: FeedbackInterface) => void;
};

const defaultFeedback: FeedbackInterface = {
  thumbsUp: null,
  suggestedFeedback: [],
  otherFeedback: null,
};

function Feedback({ initialFeedback, context, onFeedbackSubmit }: FeedbackProps): JSX.Element {
  const [feedback, setFeedback] = useState<FeedbackInterface>(initialFeedback ?? defaultFeedback);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [otherFeedback, setOtherFeedback] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (initialFeedback) {
      setFeedback(initialFeedback);
      setFeedbackSubmitted((initialFeedback.otherFeedback?.length ?? 0) > 0);
      setOtherFeedback(initialFeedback.otherFeedback ?? null);
    }
    setIsLoading(false);
  }, [initialFeedback]);

  const handleThumbClick = (thumbsUp: boolean) => {
    if (thumbsUp !== feedback.thumbsUp) {
      const newFeedback = {
        ...feedback,
        thumbsUp,
        otherFeedback: null,
        suggestedFeedback: [],
      };
      setFeedback(newFeedback);
      if (thumbsUp) {
        onFeedbackSubmit(newFeedback);
        setFeedbackSubmitted(true);
      }
    } else if (thumbsUp === feedback.thumbsUp) {
      setFeedback(defaultFeedback);
    }
  };

  const handleChipClick = (suggestion: FeedbackOptions) => {
    setFeedback((prevFeedback) => {
      const newSuggestedFeedback = prevFeedback.suggestedFeedback.includes(suggestion)
        ? prevFeedback.suggestedFeedback.filter((s) => s !== suggestion)
        : [...prevFeedback.suggestedFeedback, suggestion];

      const newFeedback = {
        ...prevFeedback,
        suggestedFeedback: newSuggestedFeedback,
      };

      onFeedbackSubmit(newFeedback);
      return newFeedback;
    });
  };

  const submitFeedback = () => {
    onFeedbackSubmit({ ...feedback, otherFeedback });
    setFeedbackSubmitted(true);
  };

  // Get feedback options based on context
  const feedbackOptions = FeedbackOptionsByContext[context];

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="left" alignItems="left" height="100%">
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (feedbackSubmitted && feedback.thumbsUp === false) {
    return (
      <div>
        <Typography sx={{ color: 'grey' }}>Thank you for your feedback!</Typography>
      </div>
    );
  }

  return (
    <div>
      <IconsContainer>
        <IconContainer onClick={() => handleThumbClick(true)}>
          {feedback.thumbsUp === true ? <ThumbUp /> : <ThumbUpOutlined />}
        </IconContainer>
        <IconContainer onClick={() => handleThumbClick(false)}>
          {feedback.thumbsUp === false ? <ThumbDown /> : <ThumbDownOutlined />}
        </IconContainer>
      </IconsContainer>
      {feedback.thumbsUp === false && (
        <div
          style={{
            margin: '20px 0px',
            padding: '20px',
            outline: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <div>
            <Typography variant="h6" sx={{ color: 'grey' }}>
              Please tell us why
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                gap: '10px',
                flexWrap: 'wrap',
                margin: '10px 0px 20px 0px',
              }}
            >
              {feedbackOptions.map((option) => (
                <Chip
                  variant={feedback.suggestedFeedback.includes(option) ? 'filled' : 'outlined'}
                  sx={{ color: 'grey', fontSize: '13px', fontWeight: '500', height: '24px' }}
                  key={option}
                  label={option}
                  onClick={() => handleChipClick(option)}
                />
              ))}
            </div>
          </div>
          <div>
            <Typography variant="h6" sx={{ color: 'grey' }}>
              Or write down your thoughts here
            </Typography>
            <ChatRow>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  onChange={(e) => setOtherFeedback(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      style: { fontSize: '14px' },
                    },
                  }}
                  multiline
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      submitFeedback();
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  boxSizing: 'border-box',
                  paddingBottom: '8px',
                }}
              >
                <Tooltip title={<Typography variant="caption"> Send </Typography>}>
                  <span>
                    <SendButton onClick={submitFeedback} color="primary">
                      <Send
                        sx={
                          feedback.suggestedFeedback.length > 0 || (otherFeedback?.length ?? 0) > 0
                            ? { color: '#1976d2' }
                            : { color: 'grey' }
                        }
                      />
                    </SendButton>
                  </span>
                </Tooltip>
              </div>
            </ChatRow>
          </div>
        </div>
      )}
    </div>
  );
}

export default Feedback;
