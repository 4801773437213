import { ChevronRightSharp, ExpandMoreSharp } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  Container,
  Drawer,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { usePrevious } from '../../hooks/usePrevious';
import type { QuestionTypeTreeNode } from '../../Types/dealGPT';
import QuestionStatus from './QuestionStatus';
import QuestionView from './QuestionView';

const CategoryViewContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  margin: '40px auto',
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#FFFDF8',
  },
});

const StyledTableCell = styled(TableCell)({
  padding: '12px 16px',
});

const StyledTableHeaderCell = styled(StyledTableCell)({
  fontWeight: '700',
  borderBottom: '2px solid #C4C4C4',
});

const StyledTypography = styled(Typography)({
  display: 'block',
  fontSize: '0.875rem',
});

const SourceDocumentsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  gap: '6px',
  color: '#1979CD',
});

const SourceDocumentsTypography = styled(StyledTypography)({
  width: '200px',
});

export type CategoryViewProps = {
  questions: QuestionTypeTreeNode[];
  expandAll: boolean;
  gleanAppId: string;
  setQuestions: (nodes: QuestionTypeTreeNode[]) => void;
};

const CategoryView = ({
  questions,
  expandAll,
  gleanAppId,
  setQuestions,
}: CategoryViewProps): JSX.Element => {
  const prevExpandAllRef = usePrevious(expandAll);

  const [openedQuestionId, setOpenedQuestionId] = useState<string | null>(null);
  const [expandedQuestions, setExpandedQuestions] = useState<Set<string>>(new Set());

  const flattendQuestions = useMemo(() => {
    const flattenByProperty = (arr: QuestionTypeTreeNode[]): QuestionTypeTreeNode[] => {
      return arr.reduce((acc: QuestionTypeTreeNode[], item: QuestionTypeTreeNode) => {
        acc.push(item);
        if (Array.isArray(item['children'])) {
          return acc.concat(flattenByProperty(item['children']));
        }
        return acc;
      }, []);
    };
    return flattenByProperty(questions);
  }, [questions]);

  const allQuestionIds = useMemo(() => {
    const ids = new Set<string>();
    const collectAllQuestionIds = (question: QuestionTypeTreeNode) => {
      ids.add(question.id);
      question.children?.forEach(collectAllQuestionIds);
    };
    questions.forEach(collectAllQuestionIds);
    return ids;
  }, [questions]);

  useEffect(() => {
    if (prevExpandAllRef !== expandAll || expandAll) {
      expandAll ? setExpandedQuestions(new Set(allQuestionIds)) : setExpandedQuestions(new Set());
    }
  }, [expandAll, prevExpandAllRef, allQuestionIds]);

  const handleOpenQuestion = (questionId: string) => {
    setOpenedQuestionId(questionId);
  };

  const handleCloseQuestion = () => {
    setOpenedQuestionId(null);
  };

  const toggleExpandQuestion = (questionId: string) => {
    setExpandedQuestions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
      }
      return newSet;
    });
  };

  const generateHierarchicalQuestions = (
    question: QuestionTypeTreeNode,
    level = 0
  ): ReactElement[] => {
    const isExpanded = expandedQuestions.has(question.id);

    const questionElement = (
      <StyledTableRow
        key={question.id}
        onClick={() => handleOpenQuestion(question.id)}
        sx={{ cursor: 'pointer' }}
      >
        <StyledTableCell
          sx={{ minWidth: '250px', alignItems: 'center', pl: `${level * 40}px` }}
          scope="row"
        >
          <div style={{ display: 'flex' }}>
            {question.children && question.children.length > 0 && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpandQuestion(question.id);
                }}
              >
                {isExpanded ? <ExpandMoreSharp /> : <ChevronRightSharp />}
              </div>
            )}

            {question.question}
          </div>
        </StyledTableCell>
        <StyledTableCell sx={{ width: '100px' }}>
          {question.documents && question.documents.length === 1 && (
            <a
              href={question.documents[0].link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <SourceDocumentsContainer>
                <DescriptionOutlinedIcon sx={{ fontSize: '16px' }} />
                <SourceDocumentsTypography noWrap>
                  {question.documents[0].fileName}
                </SourceDocumentsTypography>
              </SourceDocumentsContainer>
            </a>
          )}
          {question.documents && question.documents.length > 1 && (
            <SourceDocumentsContainer>
              <DescriptionOutlinedIcon sx={{ fontSize: '16px' }} />
              <SourceDocumentsTypography noWrap>
                {question.documents.length} documents
              </SourceDocumentsTypography>
            </SourceDocumentsContainer>
          )}
        </StyledTableCell>
        <StyledTableCell align="right">
          <QuestionStatus status={question.status} />
        </StyledTableCell>
      </StyledTableRow>
    );

    const childElements = isExpanded
      ? question.children?.flatMap((childQuestion) =>
          generateHierarchicalQuestions(childQuestion, level + 1)
        ) || []
      : [];

    return [questionElement, ...childElements];
  };

  return (
    <CategoryViewContainer maxWidth="lg">
      <TableContainer
        sx={{
          overflowX: 'auto',
          maxWidth: '1200px',
        }}
      >
        <Table id={'category-questions-container'} stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>Question</StyledTableHeaderCell>
              <StyledTableHeaderCell>Source(s)</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right">Status</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.flatMap((question) => generateHierarchicalQuestions(question))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {flattendQuestions &&
          flattendQuestions.map((question) => (
            <Drawer
              open={question.id === openedQuestionId}
              anchor="right"
              onClose={handleCloseQuestion}
              key={question.id}
            >
              <QuestionView
                gleanAppId={gleanAppId}
                setQuestions={setQuestions}
                questions={flattendQuestions}
                questionsTree={questions}
                question={question}
                handleCloseQuestion={handleCloseQuestion}
              />
            </Drawer>
          ))}
      </div>
    </CategoryViewContainer>
  );
};

export default CategoryView;
