import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

const ModificationConfirmationDialog = ({
  onConfirm,
  onCancel,
  isOpen,
  setIsOpen,
  title,
  content,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  title: string;
  content?: string;
}) => {
  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {content && (
          <DialogContent>
            <Typography>{content}</Typography>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModificationConfirmationDialog;
