import { AutoMode } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  List,
  ListItem,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ChatStatsIcon from '../../../assets/chat-stats-icon.svg';
import DefaultUserStatsIcon from '../../../assets/default-user-stats-icon.svg';
import MessagesStatsIcon from '../../../assets/messages-stats-icon.svg';
import { selectAppStatus } from '../../../redux/reducers/appStatusReducer';
import { selectPrompts } from '../../../redux/reducers/promptReducer';
import { getHomePageStats } from '../../../services/stats';
import { Conversation } from '../../../Types/conversation';
import { AppEnum } from '../../../Types/enums';
import { Prompt } from '../../../Types/prompt';
import { HomePageStats } from '../../../Types/stats';
import { useIsMobile } from '../../../util/useIsMobile';
import { useFeatures } from '../../Providers/FeatureProvider';
import CarouselViewer from './CarouselViewer';

const TitleBox = styled('div')(({ theme }) => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    marginBottom: '30px',
    marginTop: '30px',
    width: isMobile ? '92vw' : '100%',
    alignItems: 'flex-start',
    color: theme.palette.utility.text.primary,
  };
});

const InfoCard = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();
  return {
    width: isMobile ? '92vw' : '280px',
    minWidth: isMobile ? '92vw' : '280px',
    minHeight: isMobile ? '100px' : '300px',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: isMobile ? '20px' : '0',
    marginLeft: isMobile ? '-15px' : '0',
    maxHeight: '300px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    h4: {
      marginLeft: isMobile ? '0' : '10px',
      marginTop: '0px',
      marginBottom: '20px',
      textAlign: isMobile ? 'center' : 'start',
      color: theme.palette.utility.text.primary,
    },
    padding: '5px',
  };
});

const SmallInfoCard = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();
  return {
    width: isMobile ? '92vw' : '280px',
    minHeight: isMobile ? '100px' : '80px',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: isMobile ? '20px' : '0',
    marginLeft: isMobile ? '-15px' : '0',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    h4: {
      marginLeft: isMobile ? '0' : '20px',
      marginTop: '30px',
      marginBottom: '20px',
      textAlign: isMobile ? 'center' : 'start',
      color: theme.palette.utility.text.primary,
    },
  };
});

const StatsContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'center' : 'flex-start',
  };
});

const StyledStatsIcon = styled('img')(() => {
  const isMobile = useIsMobile();
  return {
    width: '60px',
    marginRight: isMobile ? '0' : '20px',
    marginBottom: isMobile ? '10px' : '0',
  };
});

interface CustomAccordionProps extends AccordionProps {
  backgroundColor?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomAccordion = styled(({ backgroundColor, ...props }: CustomAccordionProps) => (
  <Accordion {...props} />
))(({ backgroundColor }) => ({
  marginBottom: '10px',
  '& .MuiAccordionSummary-root': {
    backgroundColor: backgroundColor || 'defaultBackgroundColor',
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: backgroundColor || 'defaultBackgroundColor',
    paddingLeft: '10px',
  },
}));

export type ChatInfoProps = {
  currentConversation?: Conversation;
  currentPrompt?: Prompt;
  shouldShowInfo: boolean;
  showBanners: boolean;
};

const ChatInfo = ({
  currentConversation,
  currentPrompt,
  shouldShowInfo,
  showBanners,
}: ChatInfoProps) => {
  const [stats, setStats] = useState<HomePageStats>({
    numUsers: 0,
    numActiveConversations: 0,
    numMessages: 0,
  });
  const { appName, selectedApp } = useSelector(selectAppStatus);
  const { topPrompts } = useSelector(selectPrompts);
  const features = useFeatures();
  const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(true);

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getHomePageStats();
      setStats(newStats);
      setShouldFetchStats(false);
    };
    if (shouldFetchStats) {
      fetchStats();
    }
  }, [shouldFetchStats]);

  const isMobile = useIsMobile();

  return (
    <div>
      {currentPrompt?.userEditRequired === false ||
      (currentConversation && currentConversation.prompt?.userEditRequired === false) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '70%' }}>
            <AutoMode sx={{ width: '36px', height: '36px', marginY: '30px' }} />
            <Typography
              variant="h4"
              sx={{ marginTop: '-20px', marginBottom: '30px', fontWeight: 700 }}
            >
              {currentPrompt
                ? currentPrompt.title
                : currentConversation && currentConversation.prompt?.title}
            </Typography>
            <Typography sx={{ fontSize: '20px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.description
                : currentConversation && currentConversation.prompt?.description}
            </Typography>
          </div>
        </div>
      ) : shouldShowInfo ? (
        <div>
          <TitleBox>
            <Typography variant="h3" sx={{ marginBottom: '10px', fontWeight: 700 }}>
              {appName === 'Nigel' && features.special_event_banner
                ? `Welcome to ${appName} 🥳🎂`
                : `Welcome to ${appName}`}
            </Typography>
            {selectedApp === AppEnum.DALLE ? (
              <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
                DALL-E is an AI image generator that transforms your text prompts into stunning
                visual art. Whether you need creative illustrations, realistic photos, or abstract
                designs, DALL-E brings your ideas to life. Explore the endless possibilities of
                AI-generated images with DALL-E!
              </Typography>
            ) : (
              <Typography variant="body1">
                {appName} is a conversational AI chatbot you can use to generate ideas, create draft
                presentations, or just brainstorm. Happy chatting!
              </Typography>
            )}
          </TitleBox>

          {showBanners &&
            (isMobile ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div style={{ width: '92vw' }}>
                  <CustomAccordion backgroundColor="#FBB917">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" sx={{ color: '#062541', fontWeight: '700' }}>
                        Stats
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InfoCard>
                        <div
                          style={{
                            flexDirection: isMobile ? 'row' : 'column',
                            justifyContent: 'space-between',
                            margin: '0 20px 20px 20px',
                            gap: '30px',
                            display: 'flex',
                          }}
                        >
                          <StatsContainer>
                            <StyledStatsIcon src={DefaultUserStatsIcon} />
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span> Users
                            </Typography>
                          </StatsContainer>
                          <StatsContainer>
                            <StyledStatsIcon src={ChatStatsIcon} />
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}>
                                {stats.numActiveConversations}
                              </span>{' '}
                              Conversations
                            </Typography>
                          </StatsContainer>
                          <StatsContainer>
                            <StyledStatsIcon src={MessagesStatsIcon} />
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span>{' '}
                              Messages
                            </Typography>
                          </StatsContainer>
                        </div>
                      </InfoCard>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion backgroundColor="#1EADC1">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" sx={{ color: '#062541', fontWeight: '700' }}>
                        Prompts
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InfoCard>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '0 20px 20px 20px',
                            flexGrow: 1,
                          }}
                        >
                          <Typography>
                            Think of them as little{' '}
                            <span style={{ color: '#FBB917' }}>“head starts”</span> that can help
                            you quickly get the info you want.
                          </Typography>

                          <br />

                          <Typography>Trending Prompts:</Typography>
                          <List sx={{ pt: '0px' }}>
                            {topPrompts.map((prompt, index) => (
                              <ListItem key={index} sx={{ pt: '0px', pl: '0px' }}>
                                <Link
                                  to={`/chat?prompt=${prompt.id}`}
                                  style={{
                                    color: 'black',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  <Typography>
                                    🚀 {prompt.title} - {prompt.createdByName}
                                  </Typography>
                                </Link>
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </InfoCard>
                    </AccordionDetails>
                  </CustomAccordion>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '10px',
                    marginBottom: '10px',
                    flexWrap: 'nowrap',
                  }}
                >
                  <InfoCard
                    sx={{ bgcolor: '#FAF8F2', '&:hover': { cursor: 'default' }, width: '100%' }}
                  >
                    <div
                      style={{
                        flexDirection: 'column',
                        margin: '0 10px 10px 10px',
                        gap: '10px',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          height: '20px',
                          color: 'gray',
                          margin: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        PROMPTS
                      </Typography>
                      <Typography>
                        Think of them as little{' '}
                        <span style={{ color: '#FBB917' }}>“head starts”</span> that can help you
                        quickly get the info you want.
                      </Typography>
                      <br />
                      <Typography>Trending Prompts:</Typography>
                      <List sx={{ pt: '0px' }}>
                        {topPrompts.map((prompt, index) => (
                          <ListItem key={index} sx={{ pt: '0px', pl: '0px' }}>
                            <Link
                              to={`/chat?prompt=${prompt.id}`}
                              style={{
                                color: 'black',
                                textDecoration: 'underline',
                              }}
                            >
                              <Typography>
                                🚀 {prompt.title} - {prompt.createdByName}
                              </Typography>
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </InfoCard>
                  <CarouselViewer />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '10px',
                    marginBottom: '20px',
                    flexWrap: 'nowrap',
                  }}
                >
                  <SmallInfoCard sx={{ bgcolor: '#FDB415', '&:hover': { cursor: 'default' } }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '10px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          minWidth: '200px',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: 'white', fontWeight: '700' }}>
                          Users
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                          <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span>
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                          alignItems: 'center',
                          position: 'relative',
                          flexDirection: 'column',
                        }}
                      >
                        <StyledStatsIcon
                          src={DefaultUserStatsIcon}
                          style={{ width: '60px', height: '60px', marginBottom: '-10px' }}
                        />
                      </div>
                    </div>
                  </SmallInfoCard>
                  <SmallInfoCard sx={{ bgcolor: '#4E5766', '&:hover': { cursor: 'default' } }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '100%',
                        alignItems: 'center',
                        margin: '10px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          minWidth: '200px',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: 'white', fontWeight: '700' }}>
                          Chats Today
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                          <span style={{ fontWeight: 'bold' }}>{stats.numActiveConversations}</span>
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          alignItems: 'flex-end',
                          position: 'relative',
                          flexDirection: 'column',
                        }}
                      >
                        <StyledStatsIcon
                          src={ChatStatsIcon}
                          style={{ width: '60px', height: '60px', marginBottom: '-10px' }}
                        />
                      </div>
                    </div>
                  </SmallInfoCard>
                  <SmallInfoCard sx={{ bgcolor: '#003D6A' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '100%',
                        alignItems: 'center',
                        margin: '10px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          minWidth: '200px',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: 'white', fontWeight: '700' }}>
                          Messages
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white' }}>
                          <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span>
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          alignItems: 'flex-end',
                          position: 'relative',
                          flexDirection: 'column',
                        }}
                      >
                        <StyledStatsIcon
                          src={MessagesStatsIcon}
                          style={{
                            filter: 'brightness(0) invert(1)',
                            width: '60px',
                            height: '60px',
                          }}
                        />
                      </div>
                    </div>
                  </SmallInfoCard>
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default ChatInfo;
