import { ArrowBack, AttachFile } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Container,
  createFilterOptions,
  MenuItem,
  styled,
  TextField as MUITextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { Select, TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import FileCard from '../../components/AIAccountCoach/FileCard';
import LoadingMask from '../../components/AIAccountCoach/LoadingMask';
import ModificationConfirmationDialog from '../../components/Prompts/ModificationConfirmationDialog';
import { useAuth } from '../../components/Providers/AuthProvider';
import { getAssistant, submitAssistantForm } from '../../redux/actions/assistantsActions';
import { selectAssistants } from '../../redux/reducers/assistantsReducer';
import { AppDispatch } from '../../redux/store';
import {
  AccountType,
  Assistant,
  AssistantFormType,
  QuestionAnswerType,
} from '../../Types/AIAccountCoach/accountCoach';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
  background: 'white',
});

const DocumentUploadContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,
  height: '100%',
  overflowY: 'auto',
}));

const StyledForm = styled('form')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '0 20px 20px 20px',
});

const StyledButton = styled(Button)(() => {
  return {
    width: '25%',
    margin: '16px',
  };
});

const StyledTextField = styled(TextField)({
  '&:hover': {
    outline: '1px solid #93D0D6',
    borderRadius: '4px',
  },
  '&:active': {
    outline: '1px solid #7EB5C0',
    borderRadius: '4px',
  },
});

const StyledLabel = styled((props: TypographyProps) => <Typography variant="h6" {...props} />)({
  fontWeight: 'bold',
  marginBottom: '10px',
});

const allowedFileTypes = `.doc,.docx,.html,.pdf,.pptx`;

const FORM_DEFAULTS: AssistantFormType = {
  account_name: '',
  account_id: '',
  account_director: '',
  account_manager: '',
  account_industry: '',
  account_sub_industry: '',
  assistant_id: '',
  assistant_name: '',
  interviewee_email: '',
  engagement_type: '',
  questions: [],
  file_attachments: [],
};

const AssitantForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { currentAssistant } = useSelector(selectAssistants);
  const [isModificationConfirmationOpen, setIsModificationConfirmationOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentForm, setCurrentForm] = useState<AssistantFormType>(FORM_DEFAULTS);
  const [formKey, setFormKey] = useState(0);
  const { assistantId } = useParams();

  const [files, setFiles] = useState<File[]>([]);
  const [questions, setQuestions] = useState<QuestionAnswerType[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [accounts, setAccounts] = useState<AccountType[]>([]);

  useEffect(() => {
    const loadAccounts = async () => {
      const module = await import('./accounts_10_14_24');
      setAccounts(module.default);
    };

    loadAccounts();
  }, []);

  useEffect(() => {
    if (assistantId && !currentAssistant) {
      dispatch(getAssistant(assistantId)).then((action: PayloadAction<Assistant | unknown>) => {
        const assistant = action.payload as Assistant;
        setQuestions(assistant.questions.map((question) => ({ question, answer: '' })) || []);
      });
    }
  }, [assistantId, currentAssistant, dispatch]);

  const handleConfirm = async () => {
    if (currentAssistant) {
      setIsSubmitting(true);
      dispatch(submitAssistantForm(currentForm)).then(() => {
        enqueueSnackbar('Form Submitted!', { variant: 'success' });

        setCurrentForm(FORM_DEFAULTS);
        setFiles([]);

        setFormKey(formKey + 1);

        setIsSubmitting(false);
      });
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No file selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    for (const file of e.target.files) {
      const fileSizeMB = file.size / 1024 ** 2;
      if (fileSizeMB > 20) {
        enqueueSnackbar('Upload Failed. File size too large. Max size: 20MB.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
    }

    setFiles([...files, ...e.target.files]);
  };

  const onSubmit = (values: AssistantFormType) => {
    const newDocumentUpload = {
      account_name: values['account_name'],
      account_id: values['account_id'],
      account_director: values['account_director'],
      account_manager: values['account_manager'],
      account_industry: values['account_industry'],
      account_sub_industry: values['account_sub_industry'],
      assistant_id: currentAssistant?.id || '',
      assistant_name: currentAssistant?.name || '',
      interviewee_email: currentUser?.email || '',
      engagement_type: values['engagement_type'],
      questions,
      file_attachments: files,
    } as AssistantFormType;

    setCurrentForm(newDocumentUpload);

    setIsModificationConfirmationOpen(true);
  };

  if (!currentAssistant) {
    return null;
  }

  return (
    <MainDiv>
      <ModificationConfirmationDialog
        onConfirm={() => handleConfirm()}
        onCancel={() => {
          setIsModificationConfirmationOpen(false);
        }}
        isOpen={isModificationConfirmationOpen}
        setIsOpen={setIsModificationConfirmationOpen}
        title={'Are you sure you want to submit these documents?'}
        content="Once you submit your documents, a report will begin generating."
      />

      <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
        <DocumentUploadContainer>
          <Container>
            <StyledButton
              onClick={() => {
                navigate('/assistants');
              }}
            >
              <ArrowBack sx={{ mr: '5px' }} />
              Return to Assitants Library
            </StyledButton>
            <FinalForm
              key={formKey}
              initialValues={{ ...FORM_DEFAULTS }}
              onSubmit={onSubmit}
              render={({ handleSubmit, form, values }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Typography
                    variant="h1"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '30px',
                    }}
                  >
                    {currentAssistant.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '30px',
                      marginTop: '10px',
                    }}
                  >
                    {currentAssistant.description}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '30px',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Please fill out this form and your results will be emailed to you
                  </Typography>
                  <Container>
                    {isSubmitting && <LoadingMask />}
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '30%',
                          paddingRight: '15px',
                          paddingTop: '15px',
                        }}
                      >
                        <StyledLabel>Account Name</StyledLabel>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <Autocomplete
                          options={accounts}
                          disableClearable
                          getOptionLabel={(account) => (account as AccountType).account_name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            limit: 500,
                          })}
                          renderInput={(params) => (
                            <MUITextField {...params} required variant="outlined" />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.account_id === value.account_id
                          }
                          value={{
                            account_name: values.account_name,
                            account_id: values.account_id,
                            account_director: values.account_director,
                            account_manager: values.account_manager,
                            account_industry: values.account_industry,
                            account_sub_industry: values.account_sub_industry,
                          }}
                          onChange={(_, newValue) => {
                            form.change('account_name', newValue?.account_name);
                            form.change('account_id', newValue?.account_id);
                            form.change('account_director', newValue?.account_director);
                            form.change('account_manager', newValue?.account_manager);
                            form.change('account_industry', newValue?.account_industry);
                            form.change('account_sub_industry', newValue?.account_sub_industry);
                          }}
                          sx={{
                            '& .MuiAutocomplete-inputRoot': {
                              paddingRight: '0 !important',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              right: '10px',
                              transform: 'translateY(50%) translateX(40%)',
                            },
                          }}
                        />
                        <Typography variant="body2">
                          <span style={{ color: 'red' }}>
                            The report will be generated based on the <u>Salesforce account name</u>{' '}
                            you select.
                          </span>{' '}
                          <i>
                            If a Private Equity account is selected, it will be inclusive of
                            portfolio company intelligence.
                          </i>
                        </Typography>
                      </div>
                    </div>

                    <br />

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '30%',
                          paddingRight: '15px',
                        }}
                      >
                        <StyledLabel>
                          Is this related to an active engagement or opportunity?
                        </StyledLabel>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <Select required name="engagement_type">
                          <MenuItem value="Active Engagement">Active Engagement</MenuItem>
                          <MenuItem value="Open Opportunity">Open Opportunity</MenuItem>
                          <MenuItem value="None">None</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
                      {currentAssistant.questions.map((question, index) => (
                        <div
                          key={index}
                          style={{ display: 'flex', width: '100%', alignItems: 'center' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '30%',
                              paddingRight: '15px',
                              paddingTop: '15px',
                            }}
                          >
                            <StyledLabel>{question}</StyledLabel>
                          </div>

                          <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                            <StyledTextField
                              name={`question_${index}`}
                              variant="outlined"
                              required
                              fullWidth
                              onBlur={(e) => {
                                setQuestions([
                                  ...questions.slice(0, index),
                                  {
                                    question,
                                    answer: e.target.value,
                                  },
                                  ...questions.slice(index + 1),
                                ]);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <br />
                    <br />
                    <br />

                    {currentAssistant.file_upload && (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Button
                            sx={{ color: 'black', border: '1px solid black' }}
                            onClick={() => {
                              if (fileInputRef.current) {
                                fileInputRef.current.click();
                              }
                            }}
                          >
                            <input
                              type="file"
                              hidden
                              multiple
                              ref={fileInputRef}
                              name="file_attachments"
                              onChange={(e) => {
                                handleFileUpload(e);
                              }}
                              accept={allowedFileTypes}
                            />
                            Choose Files
                            <AttachFile sx={{ color: 'black' }} />
                          </Button>
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '20px',
                              gap: '20px',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                            }}
                          >
                            {files.map((file, index) => (
                              <FileCard
                                key={index}
                                file={file}
                                handleFileDelete={() => {
                                  setFiles(files.filter((f) => f !== file));
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <StyledButton type="submit" variant="contained">
                        Submit
                      </StyledButton>
                    </div>
                  </Container>
                </StyledForm>
              )}
            />
          </Container>
        </DocumentUploadContainer>
      </div>
    </MainDiv>
  );
};

export default AssitantForm;
