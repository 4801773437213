import { FeedbackOptions } from '../Types/enums';

export const FeedbackContext = {
  MESSAGE: 'MESSAGE',
  PROJECT_QUESTION: 'PROJECT_QUESTION',
};

export const FeedbackOptionsByContext = {
  [FeedbackContext.MESSAGE]: [
    FeedbackOptions.NOT_ENOUGH_INFORMATION,
    FeedbackOptions.TOO_VAGUE,
    FeedbackOptions.UNHELPFUL,
    FeedbackOptions.BEING_LAZY,
    FeedbackOptions.INCORRECT_CONTEXT,
    FeedbackOptions.INACCURATE_FALSE_INFORMATION,
    FeedbackOptions.SLOW_RESPONSE_TIME,
    FeedbackOptions.WRONG_TONE,
  ],
  [FeedbackContext.PROJECT_QUESTION]: [
    FeedbackOptions.INCORRECT_CONTEXT,
    FeedbackOptions.INACCURATE_FALSE_INFORMATION,
    FeedbackOptions.INCOMPLETE_ANSWER,
    FeedbackOptions.IRRELEVANT_ANSWER,
    FeedbackOptions.OUTDATED_INFORMATION,
    FeedbackOptions.UNCLEAR_CONFUSING,
    FeedbackOptions.TECHNICAL_ISSUES,
  ],
};
