import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Conversation, ConversationResponse, Message } from '../../Types/conversation';
import { getConversation, getConversations } from '../actions/conversationActions';
import { CONVERSATION_LIST_SIZE } from '../consts';
import { RootState } from '../store';

interface ConversationsState {
  conversations: Conversation[];
  currentConversation?: Conversation;
  currentMessages: Message[];
  totalCount: number;
  isLoading: boolean;
  isScrolling: boolean;
  searchQuery: string;
  page: number;
  currentNumTokens: number;
  maxNumTokens: number;
  conversationSearchSelected: boolean;
}

const initialState: ConversationsState = {
  conversations: [],
  currentConversation: undefined,
  currentMessages: [],
  totalCount: 0,
  isLoading: false,
  isScrolling: false,
  searchQuery: '',
  page: 1,
  currentNumTokens: 0,
  maxNumTokens: 128000,
  conversationSearchSelected: false,
};

// Define the type for the rejected action
interface RejectedAction extends Action {
  payload: string;
}

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = action.payload;
    },
    setCurrentConversation: (state, action: PayloadAction<Conversation | undefined>) => {
      state.currentConversation = action.payload;
    },
    setCurrentMessages: (state, action: PayloadAction<Message[]>) => {
      state.currentMessages = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsScrolling: (state, action: PayloadAction<boolean>) => {
      state.isScrolling = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    setCurrentNumTokens: (state, action: PayloadAction<number>) => {
      state.currentNumTokens = action.payload;
    },
    setMaxNumTokens: (state, action: PayloadAction<number>) => {
      state.maxNumTokens = action.payload;
    },
    setConversationSearchSelected: (state, action: PayloadAction<boolean>) => {
      state.conversationSearchSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.fulfilled, (state, action: PayloadAction<ConversationResponse>) => {
        const { data, totalCount } = action.payload;
        const pageOffset = CONVERSATION_LIST_SIZE * (state.page - 1);
        if (pageOffset === 0) {
          state.conversations = data;
        } else {
          state.conversations = [...state.conversations, ...data];
        }
        state.totalCount = totalCount;
        state.isLoading = false;
      })
      .addCase(getConversation.fulfilled, (state, action: PayloadAction<Conversation>) => {
        state.currentConversation = action.payload;
      })
      .addMatcher(
        (action: Action): action is RejectedAction =>
          action.type.startsWith('conversations/') && action.type.endsWith('/rejected'),
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const {
  setConversationSearchSelected,
  setCurrentConversation,
  setCurrentMessages,
  setIsLoading,
  setIsScrolling,
  setPage,
  setSearchQuery,
  setCurrentNumTokens,
  setMaxNumTokens,
} = conversationsSlice.actions;

export const selectConversations = (state: RootState) => state.conversations;

export default conversationsSlice.reducer;
