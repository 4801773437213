import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingMask = () => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 5,
          background: 'white',
          opacity: 0.8,
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          paddingTop: '10px',
          zIndex: 6,
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};

export default LoadingMask;
