import { InsertDriveFileOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { Card, CardHeader, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';

const StyledCard = styled(Card)({
  marginRight: 'auto',
});

const FileCard = ({ file, handleFileDelete }: { file: File; handleFileDelete: () => void }) => {
  return (
    <StyledCard>
      <CardHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px',
        }}
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InsertDriveFileOutlined fontSize="small" style={{ marginRight: '10px' }} />
              <Typography variant="caption2" fontSize="small" sx={{ lineHeight: '16px' }}>
                {file.name}
                <span style={{ color: 'grey', marginLeft: '5px', lineHeight: '16px' }}>
                  {Math.ceil((file.size / (1024 * 1024)) * 10) / 10}
                  {'MB'}
                </span>
              </Typography>
            </div>

            <IconButton onClick={() => handleFileDelete()} size="small">
              <Tooltip title="Delete File">
                <RemoveCircleOutline fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        }
      ></CardHeader>
    </StyledCard>
  );
};

export default FileCard;
