import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { ArgumentType } from '../../Types/gtag.d';
import { useAuth } from './AuthProvider';

declare global {
  interface Window {
    gtag: (...args: ArgumentType[]) => void;
  }
}

type SessionOptions = {
  userId: string;
  sessionId: string;
};

type SessionStorage = {
  value: SessionOptions;
};

type MessageOptions = {
  conversationId: string;
  messageId: string;
  overridedTimestamp?: string;
};

type ChatDownloadOptions = {
  conversationId: string;
};

type FileOptions = {
  fileId: string;
  fileSize: number;
  overridedTimestamp?: string;
};

type RegenerateResponseOptions = {
  messageId: string;
  llm: string;
};

type LLMChangeOptions = {
  oldllm: string | undefined;
  newLlm: string;
};

type OverridedOptions = {
  timestamp: string;
  newOptions: Record<string, unknown>;
};

interface AnalyticsContextType {
  regenerateResponse: (options: RegenerateResponseOptions) => void;
  getTimestamp: () => string;
  getTimestampAsDate: () => Date;
  getElapsedTime: (startTimestamp: Date, endTimestamp: Date) => string;
  message: (status: 'elapsedTime', options: MessageOptions) => void;
  fileUpload: (status: 'elapsedTime', options: FileOptions) => void;
  fileTooLargeForUpload: (options: FileOptions) => void;
  chatDownload: (options: ChatDownloadOptions) => void;
  llmChange: (options: LLMChangeOptions) => void;
}

interface Props {
  children: React.ReactNode;
}

export const AnalyticsHelper = (): AnalyticsContextType => {
  function getStorage(): SessionStorage | null {
    const value: string | null = sessionStorage.getItem('_session');
    const result: SessionStorage | null = value ? (JSON.parse(value) as SessionStorage) : null;

    return result;
  }

  function setStorage(userId: string, sessionId: string): SessionStorage {
    const storage: SessionStorage = {
      value: {
        userId: userId,
        sessionId: sessionId,
      },
    };
    sessionStorage.setItem('_session', JSON.stringify(storage));
    return storage;
  }

  const [session, setSession] = useState<SessionOptions | null>(null);
  const { initialized, currentUser } = useAuth();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const userId: string = currentUser?.id || '';

    let storageData = getStorage();
    let sessionId = '';

    if (!storageData || storageData.value.sessionId === '') {
      sessionId = uuidv4();
    } else {
      sessionId = storageData.value.sessionId;
    }
    storageData = setStorage(userId, sessionId);
    setSession(storageData.value);
  }, [initialized, currentUser?.id]);

  function getTimestamp(): string {
    const now = new Date();
    return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
  }

  function getTimestampAsDate(): Date {
    const now = new Date();
    return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  }

  function getElapsedTime(startTimestamp: Date, endTimestamp: Date): string {
    return (endTimestamp.getTime() - startTimestamp.getTime()).toString();
  }
  function getOverridedOptions(options: Record<string, unknown>): OverridedOptions {
    const timestamp: string = options.overridedTimestamp
      ? (options.overridedTimestamp as string)
      : getTimestamp();

    const newOptions = options;
    delete newOptions.overridedTimestamp;

    return {
      timestamp,
      newOptions,
    };
  }

  const contextType: AnalyticsContextType = {
    getTimestamp: (): string => {
      return getTimestamp();
    },
    message: (status: 'elapsedTime', options: MessageOptions): void => {
      const { timestamp, newOptions } = getOverridedOptions(options);
      window.gtag('event', 'WM_NIGEL_MESSAGE', {
        ...session,
        ...newOptions,
        status,
        timestamp,
      });
    },
    fileUpload: (status: 'elapsedTime', options: FileOptions): void => {
      const { timestamp, newOptions } = getOverridedOptions(options);
      window.gtag('event', 'WM_NIGEL_FILE_UPLOAD', {
        ...session,
        ...newOptions,
        status,
        timestamp,
      });
    },
    fileTooLargeForUpload: (options: FileOptions): void => {
      const { timestamp, newOptions } = getOverridedOptions(options);
      window.gtag('event', 'WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT', {
        ...session,
        ...newOptions,
        timestamp,
      });
    },
    chatDownload: (options: ChatDownloadOptions): void => {
      window.gtag('event', 'WM_NIGEL_CHAT_DOWNLOAD', {
        ...session,
        ...options,
        timestamp: getTimestamp(),
      });
    },
    getTimestampAsDate: function (): Date {
      return getTimestampAsDate();
    },
    getElapsedTime: function (startTimestamp: Date, endTimestamp: Date): string {
      return getElapsedTime(startTimestamp, endTimestamp);
    },
    regenerateResponse: function (options: RegenerateResponseOptions): void {
      window.gtag('event', 'WM_NIGEL_REGENERATE_RESPONSE', {
        ...session,
        ...options,
        timestamp: getTimestamp(),
      });
    },
    llmChange: function (options: LLMChangeOptions): void {
      window.gtag('event', 'WM_NIGEL_LLM_CHANGE', {
        ...session,
        ...options,
        timestamp: getTimestamp(),
      });
    },
  };

  return contextType;
};

const AnalyticsContext: React.Context<AnalyticsContextType | null> =
  createContext<AnalyticsContextType | null>(null);

export const AnalyticsProvider = ({ children }: Props): React.ReactElement => {
  const analyticsContextType = AnalyticsHelper();

  return (
    <AnalyticsContext.Provider value={analyticsContextType}>{children}</AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return analyticsContext;
};
