import styled from '@emotion/styled';
import {
  Attachment,
  DeleteOutlined,
  EditOutlined,
  FileDownloadOutlined,
  LinkOutlined,
  MoreHoriz,
  SyncOutlined,
} from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  CardContent,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { forwardRef, useCallback, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { createMessageSnippet } from '../../util/textUtils';
import timeSince from '../../util/timeUitls';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import RenameDialog from './RenameDialog';
import ShareDialog from './ShareDialog';

const DrawerContainer = styled('div')<{ selected: boolean }>(({ selected }) => ({
  width: '100%',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.05)',
  },
  borderRadius: '4px',
  boxShadow: '0px 4px 8px 4px rgba(16, 24, 40, 0.01)',

  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  background: selected ? '#FFF0DA' : '#fff',
  border: selected ? '2px solid #B66D03' : '2px solid #fff',
  borderColor: selected ? '#B66D03' : '#fff',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  padding: '0.2rem',
}));

const StyledDrawerCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
  padding: '10px',
  ':last-child': {
    paddingBottom: '10px',
  },
});

const StyledCardActionArea = styled(CardActionArea)({
  ':hover': {
    '.MuiCardActionArea-focusHighlight': {
      opacity: 0,
    },
  },
});

const IconButtonContainer = styled('div')({
  right: '0px',
  top: '0px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const IconLabel = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
  gap: '5px',
  textAlign: 'center',
});

type DrawerCardProps = {
  id: string;
  title: string;
  time: string;
  appName: string;
  selected: boolean;
  hasFileUploaded: boolean;
  shared: boolean;
  canBeShared: boolean;
  hasPrompt: boolean;
  type: string;
  onDrawerCardClick: () => void;
  onDrawerCardUpdate: () => void;
  onDrawerCardRename: (newTitle: string) => void;
  onDrawerCardDelete: () => void;
  onDrawerCardShareUpdate: (newValue: boolean) => void;
  handleDrawerCardDownload: () => void;
  reusePrompt: () => void;
  matchingMessage?: string;
  searchQuery: string;
};

const DrawerCard = (
  {
    title,
    time,
    appName,
    selected,
    hasFileUploaded,
    shared,
    canBeShared,
    hasPrompt,
    type,
    onDrawerCardClick,
    onDrawerCardUpdate,
    onDrawerCardRename,
    onDrawerCardDelete,
    onDrawerCardShareUpdate,
    handleDrawerCardDownload,
    reusePrompt,
    id,
    matchingMessage,
    searchQuery,
  }: DrawerCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [localShared, setLocalShared] = useState(shared);

  const handleOpenDrawerCardContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleDrawerCardRename = (newTitle: string) => {
    onDrawerCardRename(newTitle);
    onDrawerCardUpdate();
    handleCloseUserMenu();
  };

  const handleDrawerCardShare = (newValue: boolean) => {
    setLocalShared(newValue);
    onDrawerCardShareUpdate(newValue);
  };

  const typeToCapital = useCallback(() => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }, [type]);

  return (
    <DrawerContainer onClick={onDrawerCardClick} selected={selected}>
      <StyledDrawerCardContent ref={ref}>
        <IconButtonContainer>
          <StyledCardActionArea>
            <Typography>
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchQuery]}
                autoEscape={true}
                textToHighlight={title}
              />
            </Typography>
          </StyledCardActionArea>
          <div onClick={(e) => e.stopPropagation()}>
            <DeleteConfirmationDialog
              isOpen={isDeleteDialogOpen}
              setIsOpen={setIsDeleteDialogOpen}
              onDelete={onDrawerCardDelete}
            />
            <RenameDialog
              isOpen={isRenameDialogOpen}
              setIsOpen={setIsRenameDialogOpen}
              currentName={title}
              onRename={(newTitle) => handleDrawerCardRename(newTitle)}
              type={type}
            />
            {canBeShared && (
              <ShareDialog
                isOpen={isShareDialogOpen}
                setIsOpen={setIsShareDialogOpen}
                onSharedUpdate={(shared: boolean) => handleDrawerCardShare(shared)}
                shared={localShared}
                conversationId={id}
              />
            )}
            <Tooltip
              id={`open-settings-label-${id}`}
              title={<Typography variant="caption"> Open settings </Typography>}
              PopperProps={{ keepMounted: true }}
            >
              <IconButton
                onClick={handleOpenDrawerCardContextMenu}
                aria-labelledby={`open-settings-label-${id}`}
                sx={{ p: 0 }}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '32px' }}
              anchorEl={anchorContextMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorContextMenu)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => setIsRenameDialogOpen(true)}>
                <IconLabel>
                  <EditOutlined />
                  Rename
                </IconLabel>
              </MenuItem>
              {canBeShared && (
                <MenuItem onClick={() => setIsShareDialogOpen(true)} data-testid="menu-item-share">
                  <IconLabel>
                    <LinkOutlined />
                    Shared Link Settings
                    <Chip
                      component="span"
                      label={localShared ? 'Shared' : 'Not Shared'}
                      variant="outlined"
                      color={localShared ? 'success' : 'default'}
                      sx={{
                        backgroundColor: localShared ? '#E2FFE2' : 'default',
                        marginLeft: '5px',
                      }}
                    />
                  </IconLabel>
                </MenuItem>
              )}
              <MenuItem onClick={handleDrawerCardDownload}>
                <IconLabel>
                  <FileDownloadOutlined /> Download {typeToCapital()} (.md)
                </IconLabel>
              </MenuItem>
              {hasPrompt && (
                <MenuItem onClick={() => reusePrompt()} data-testid="menu-item-prompt">
                  <IconLabel>
                    <SyncOutlined />
                    Reuse Prompt
                  </IconLabel>
                </MenuItem>
              )}
              <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                <IconLabel>
                  <DeleteOutlined />
                  Delete
                </IconLabel>
              </MenuItem>
            </Menu>
          </div>
        </IconButtonContainer>
        {matchingMessage && (
          <Typography variant="body2" color="textSecondary">
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchQuery]}
              autoEscape={true}
              textToHighlight={createMessageSnippet(matchingMessage, searchQuery)}
            />
          </Typography>
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.5)' }}>
            {timeSince(new Date(time))}
          </Typography>
          <Box display="flex" gap={1}>
            <Typography sx={{ fontSize: '10px' }}>{appName}</Typography>
            {hasFileUploaded && (
              <Tooltip title={`${typeToCapital()} has uploaded files`}>
                <Attachment sx={{ fontSize: '1.2rem', color: 'rgba(0,0,0,0.5)' }} />
              </Tooltip>
            )}
          </Box>
        </div>
      </StyledDrawerCardContent>
    </DrawerContainer>
  );
};

DrawerCard.displayName = 'DrawerCard';

export default forwardRef(DrawerCard);
