import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'redux/store';

import { API_URL, enrichedFetch } from '../../services/api';
import {
  Assistant,
  AssistantAPIResponse,
  AssistantFormType,
} from '../../Types/AIAccountCoach/accountCoach';

export const getAssistants = createAsyncThunk<AssistantAPIResponse, void, { state: RootState }>(
  'assistants/getAssistants',
  async (_, { getState, rejectWithValue }) => {
    const { pageOffset } = getState().assistants;
    try {
      const response = (await enrichedFetch(
        `${API_URL}/assistants?offset=${pageOffset}`
      )) as AssistantAPIResponse;
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch assistants', { variant: 'error' });
      return rejectWithValue('Failed to fetch assistants');
    }
  }
);

export const getAssistant = createAsyncThunk<Assistant, Assistant['id'], { state: RootState }>(
  'assistants/getAssistant',
  async (id, { rejectWithValue }) => {
    try {
      const response = (await enrichedFetch(`${API_URL}/assistants/${id}`)) as Assistant;

      return response;
    } catch {
      enqueueSnackbar('Failed to fetch assistant', { variant: 'error' });
      return rejectWithValue('Failed to fetch assistant');
    }
  }
);

export const submitAssistantForm = createAsyncThunk<void, AssistantFormType, { state: RootState }>(
  'assistants/submitAssistantForm',
  async (assistantForm, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append('account_name', assistantForm.account_name);
    formData.append('account_id', assistantForm.account_id);
    formData.append('account_director', assistantForm.account_director);
    formData.append('account_manager', assistantForm.account_manager);
    formData.append('account_industry', assistantForm.account_industry);
    formData.append('account_sub_industry', assistantForm.account_sub_industry);
    formData.append('assistant_id', assistantForm.assistant_id);
    formData.append('assistant_name', assistantForm.assistant_name);
    formData.append('interviewee_email', assistantForm.interviewee_email);
    formData.append('engagement_type', assistantForm.engagement_type);
    formData.append('questions', JSON.stringify(assistantForm.questions));
    if (assistantForm.file_attachments.length > 0) {
      assistantForm.file_attachments.forEach((file) => {
        formData.append('file_attachments', file);
      });
    }

    try {
      await enrichedFetch(`${API_URL}/assistants/submitAssistantForm`, {
        method: 'POST',
        body: formData,
      });

      return;
    } catch {
      enqueueSnackbar('Retool Error', { variant: 'error' });
      return rejectWithValue('Retool Error');
    }
  }
);
