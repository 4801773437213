import { User, UserMetadata, UserPreferredName } from 'Types/user';

import { API_URL, enrichedFetch } from './api';

export const updateUserMetadata = async (userMetadata: UserMetadata): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/updateMetadata`, {
    method: 'PUT',
    body: JSON.stringify(userMetadata),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<User>;
};

export const updateUserPreferredName = async (name: UserPreferredName): Promise<User> => {
  return enrichedFetch(`${API_URL}/user/updateName`, {
    method: 'PUT',
    body: JSON.stringify(name),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<User>;
};
