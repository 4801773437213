import { AttachFile } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Container,
  createFilterOptions,
  IconButton,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MUITextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';

import FileCard from '../../components/AIAccountCoach/FileCard';
import LoadingMask from '../../components/AIAccountCoach/LoadingMask';
import ModificationConfirmationDialog from '../../components/Prompts/ModificationConfirmationDialog';
import { useAuth } from '../../components/Providers/AuthProvider';
import { API_URL, enrichedFetch } from '../../services/api';
import { AccountType, DocumentUploadType } from '../../Types/AIAccountCoach/accountCoach';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
});

const DocumentUploadContainer = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  zIndex: 1,
  background: 'white',
  height: '100%',
  overflowY: 'auto',
}));

const StyledForm = styled('form')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '0 20px 20px 20px',
});

const StyledSubmitButton = styled(Button)(() => {
  return {
    width: '25%',
    margin: '16px',
  };
});

const StyledTextField = styled(TextField)({
  '&:hover': {
    outline: '1px solid #93D0D6',
    borderRadius: '4px',
  },
  '&:active': {
    outline: '1px solid #7EB5C0',
    borderRadius: '4px',
  },
});

const StyledLabel = styled((props: TypographyProps) => <Typography variant="h6" {...props} />)({
  fontWeight: 'bold',
  marginBottom: '10px',
});

const allowedFileTypes = `.doc,.docx,.html,.pdf,.pptx`;

const DOCUMENT_UPLOAD_DEFAULTS: DocumentUploadType = {
  account_name: '',
  account_id: '',
  account_director: '',
  account_manager: '',
  account_industry: '',
  account_sub_industry: '',
  interviewee_email: '',
  receiver_email: '',
  work_prop_dev_files: [],
  value_dev_files: [],
  buyer_int_files: [],
  int_materials_files: [],
  ext_materials_files: [],
};

const DocumentUpload = () => {
  const { currentUser } = useAuth();
  const [isModificationConfirmationOpen, setIsModificationConfirmationOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentDocumentUpload, setCurrentDocumentUpload] =
    useState<DocumentUploadType>(DOCUMENT_UPLOAD_DEFAULTS);
  const [formKey, setFormKey] = useState(0);

  const [workPropDevFiles, setWorkPropDevFiles] = useState<File[]>([]);
  const [valueDevFiles, setValueDevFiles] = useState<File[]>([]);
  const [buyerIntFiles, setBuyerIntFiles] = useState<File[]>([]);
  const [intMaterialsFiles, setIntMaterialsFiles] = useState<File[]>([]);
  const [extMaterialsFiles, setExtMaterialsFiles] = useState<File[]>([]);
  const [accounts, setAccounts] = useState<AccountType[]>([]);

  useEffect(() => {
    const loadAccounts = async () => {
      const module = await import('./accounts_10_14_24');
      setAccounts(module.default);
    };

    loadAccounts();
  }, []);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('account_name', currentDocumentUpload.account_name);
    formData.append('account_id', currentDocumentUpload.account_id);
    formData.append('account_director', currentDocumentUpload.account_director);
    formData.append('account_manager', currentDocumentUpload.account_manager);
    formData.append('account_industry', currentDocumentUpload.account_industry);
    formData.append('account_sub_industry', currentDocumentUpload.account_sub_industry);
    formData.append('interviewee_email', currentDocumentUpload.interviewee_email);
    formData.append('receiver_email', currentDocumentUpload.receiver_email);

    currentDocumentUpload.work_prop_dev_files.forEach((file) => {
      formData.append('work_prop_dev_files', file);
    });

    currentDocumentUpload.value_dev_files.forEach((file) => {
      formData.append('value_dev_files', file);
    });

    currentDocumentUpload.buyer_int_files.forEach((file) => {
      formData.append('buyer_int_files', file);
    });

    currentDocumentUpload.int_materials_files.forEach((file) => {
      formData.append('int_materials_files', file);
    });

    currentDocumentUpload.ext_materials_files.forEach((file) => {
      formData.append('ext_materials_files', file);
    });

    try {
      await enrichedFetch(`${API_URL}/account-coach/upload`, {
        method: 'POST',
        body: formData,
      });

      enqueueSnackbar('Documents Submitted!', { variant: 'success' });

      setTimeout(() => {
        window.location.href =
          'https://westmonroepartners1.sharepoint.com/sites/WM-ExpertGPTs/SitePages/AI-Account-Coach.aspx?csf=1&web=1&e=8CVHYo&ovuser=c2a23be5-d2cf-4e70-84ce-18adf87d571f%2Cjklobucar%40westmonroepartners.com&OR=Teams-HL&CT=1726629090365&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA4MTcwMDQxOSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&CID=9e5451a1-20b0-6000-936f-42ae1d50a8ca&cidOR=SPO';
      }, 2000);

      setCurrentDocumentUpload(DOCUMENT_UPLOAD_DEFAULTS);
      setWorkPropDevFiles([]);
      setValueDevFiles([]);
      setBuyerIntFiles([]);
      setIntMaterialsFiles([]);
      setExtMaterialsFiles([]);

      setFormKey(formKey + 1);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Retool Error', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No file selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    for (const file of e.target.files) {
      const fileSizeMB = file.size / 1024 ** 2;
      if (fileSizeMB > 20) {
        enqueueSnackbar('Upload Failed. File size too large. Max size: 20MB.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
    }

    switch (field) {
      case 'work_prop_dev_files':
        if (workPropDevFiles.length + e.target.files.length > 10) {
          enqueueSnackbar(
            'Upload Failed. Please keep the file submissions to 10 files or fewer per section.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        } else {
          setWorkPropDevFiles([...workPropDevFiles, ...e.target.files]);
        }
        break;
      case 'value_dev_files':
        if (valueDevFiles.length + e.target.files.length > 10) {
          enqueueSnackbar(
            'Upload Failed. Please keep the file submissions to 10 files or fewer per section.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        } else {
          setValueDevFiles([...valueDevFiles, ...e.target.files]);
        }
        break;
      case 'buyer_int_files':
        if (buyerIntFiles.length + e.target.files.length > 10) {
          enqueueSnackbar(
            'Upload Failed. Please keep the file submissions to 10 files or fewer per section.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        } else {
          setBuyerIntFiles([...buyerIntFiles, ...e.target.files]);
        }
        break;
      case 'int_materials_files':
        if (intMaterialsFiles.length + e.target.files.length > 10) {
          enqueueSnackbar(
            'Upload Failed. Please keep the file submissions to 10 files or fewer per section.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        } else {
          setIntMaterialsFiles([...intMaterialsFiles, ...e.target.files]);
        }
        break;
      case 'ext_materials_files':
        if (extMaterialsFiles.length + e.target.files.length > 10) {
          enqueueSnackbar(
            'Upload Failed. Please keep the file submissions to 10 files or fewer per section.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        } else {
          setExtMaterialsFiles([...extMaterialsFiles, ...e.target.files]);
        }
        break;
      default:
        break;
    }
  };

  const onSubmit = (values: DocumentUploadType) => {
    const newDocumentUpload = {
      account_name: values['account_name'],
      account_id: values['account_id'],
      account_director: values['account_director'],
      account_manager: values['account_manager'],
      account_industry: values['account_industry'],
      account_sub_industry: values['account_sub_industry'],
      interviewee_email: currentUser?.email || '',
      receiver_email: values['receiver_email'],
      work_prop_dev_files: workPropDevFiles,
      value_dev_files: valueDevFiles,
      buyer_int_files: buyerIntFiles,
      int_materials_files: intMaterialsFiles,
      ext_materials_files: extMaterialsFiles,
    } as DocumentUploadType;

    setCurrentDocumentUpload(newDocumentUpload);

    setIsModificationConfirmationOpen(true);
  };

  return (
    <MainDiv>
      <ModificationConfirmationDialog
        onConfirm={() => handleConfirm()}
        onCancel={() => {
          setIsModificationConfirmationOpen(false);
        }}
        isOpen={isModificationConfirmationOpen}
        setIsOpen={setIsModificationConfirmationOpen}
        title={'Are you sure you want to submit these documents?'}
        content="Once you submit your documents, a report will begin generating."
      />

      <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
        <DocumentUploadContainer>
          <Container>
            <FinalForm
              key={formKey}
              initialValues={{ ...DOCUMENT_UPLOAD_DEFAULTS }}
              onSubmit={onSubmit}
              render={({ handleSubmit, form, values }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Typography
                    variant="h1"
                    sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                  >
                    Welcome to the AI Account Coach!
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                  >
                    To get started, please complete the form below. This will help the AI Account
                    Coach determine the appropriate account for generating the output.
                  </Typography>
                  <Container>
                    {isSubmitting && <LoadingMask />}
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          width: '30%',
                          paddingRight: '15px',
                          paddingTop: '15px',
                        }}
                      >
                        <StyledLabel>Account Name</StyledLabel>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <Autocomplete
                          options={accounts}
                          disableClearable
                          getOptionLabel={(account) => (account as AccountType).account_name}
                          filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            limit: 500,
                          })}
                          renderInput={(params) => (
                            <MUITextField {...params} required variant="outlined" />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.account_id === value.account_id
                          }
                          value={{
                            account_name: values.account_name,
                            account_id: values.account_id,
                            account_director: values.account_director,
                            account_manager: values.account_manager,
                            account_industry: values.account_industry,
                            account_sub_industry: values.account_sub_industry,
                          }}
                          onChange={(_, newValue) => {
                            form.change('account_name', newValue?.account_name);
                            form.change('account_id', newValue?.account_id);
                            form.change('account_director', newValue?.account_director);
                            form.change('account_manager', newValue?.account_manager);
                            form.change('account_industry', newValue?.account_industry);
                            form.change('account_sub_industry', newValue?.account_sub_industry);
                          }}
                          sx={{
                            '& .MuiAutocomplete-inputRoot': {
                              paddingRight: '0 !important',
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                              right: '10px',
                              transform: 'translateY(50%) translateX(40%)',
                            },
                          }}
                        />
                        <Typography variant="body2">
                          <span style={{ color: 'red' }}>
                            The report will be generated based on the <u>Salesforce account name</u>{' '}
                            you select.
                          </span>{' '}
                          <i>
                            If a Private Equity account is selected, it will be inclusive of
                            portfolio company intelligence.
                          </i>
                        </Typography>
                      </div>
                    </div>

                    <br />

                    <div style={{ display: 'flex', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          width: '30%',
                          paddingRight: '15px',
                          paddingTop: '15px',
                        }}
                      >
                        <StyledLabel>Email</StyledLabel>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <StyledTextField
                          name="receiver_email"
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <Typography variant="body2">
                          <i>
                            A copy of the report will be sent to this West Monroe email address.
                          </i>
                        </Typography>
                      </div>
                    </div>
                    <br />

                    <Typography variant="body1">
                      Only one team member can upload documents for the account. Before adding your
                      5-10 documents, please collect all necessary documents from your team.{' '}
                      <b>
                        The quality of the output depends on the quality of the input, so ensure
                        that at least one document that is most recent and most relevant is selected
                        for each section below.
                      </b>{' '}
                      <br />
                      <br />
                      <i>
                        We recommend creating an AI Account Coach SharePoint file for your teams to
                        add documents. Then download that folder to your desktop before adding the
                        documents here.
                      </i>
                      <br />
                      <br />
                    </Typography>

                    <Table>
                      <TableHead>
                        <TableRow sx={{ bgcolor: '#2E5F7F' }}>
                          <TableCell sx={{ width: '40%', textAlign: 'center', color: 'white' }}>
                            <Typography variant="h3">Category</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '30%', textAlign: 'center', color: 'white' }}>
                            <Typography variant="h3">File Upload</Typography>
                            20 MB limit for each file
                            <br />
                            only include PowerPoint, Word Docs and PDFs
                            <br />
                            <br />
                            <Link
                              href="https://westmonroepartners1.sharepoint.com/:b:/g/SharedServices/Marketing/EYzefU0XLzJJumlPR6bppEoBKv3FP3aLxQ0ZMyXFZPXfoQ?e=bj0o0f"
                              target="_blank"
                              sx={{ color: 'white', textDecorationColor: 'white' }}
                            >
                              How to compress a PowerPoint file
                            </Link>
                            <br />
                            <br />
                            <Link
                              href="https://westmonroepartners1.sharepoint.com/:b:/g/SharedServices/Marketing/EZIryGb0scJOt2h5RQNsiRoBilQkodOxbR2037CWJZUTIw?e=mmIhVE"
                              target="_blank"
                              sx={{ color: 'white', textDecorationColor: 'white' }}
                            >
                              How to convert Excel to PDF
                            </Link>
                          </TableCell>
                          <TableCell sx={{ width: '30%', textAlign: 'center', color: 'white' }}>
                            <Typography variant="h3">Helpful Tips</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ bgcolor: '#CDD2D7' }}>
                          <TableCell>
                            <b>Work Proposed and Delivered</b> <br />
                            <i>SOWs, RFPs, Proposals</i>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                border: 'none',
                              }}
                            >
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                              >
                                {workPropDevFiles.map((file, index) => (
                                  <FileCard
                                    key={index}
                                    file={file}
                                    handleFileDelete={() => {
                                      setWorkPropDevFiles(
                                        workPropDevFiles.filter((_, i) => i !== index)
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconButton component="label">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    name="work_prop_dev_files"
                                    onChange={(e) => {
                                      handleFileUpload(e, 'work_prop_dev_files');
                                    }}
                                    accept={allowedFileTypes}
                                  />
                                  <AttachFile sx={{ color: 'black' }} />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            You can find all SOWs in Salesforce on the won opportunity
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ bgcolor: '#E7EAED' }}>
                          <TableCell>
                            <b>Value Delivered</b> <br />
                            <i>
                              QBRs/Value Acceleration Discussion, Project Status Meetings,
                              Workshops, Final Client Readouts, KPI tracking, Case Studies, etc.
                            </i>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                              >
                                {valueDevFiles.map((file, index) => (
                                  <FileCard
                                    key={index}
                                    file={file}
                                    handleFileDelete={() => {
                                      setValueDevFiles(valueDevFiles.filter((_, i) => i !== index));
                                    }}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconButton component="label">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    name="value_dev_files"
                                    onChange={(e) => {
                                      handleFileUpload(e, 'value_dev_files');
                                    }}
                                    accept={allowedFileTypes}
                                  />
                                  <AttachFile sx={{ color: 'black' }} />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Link
                              href="https://westmonroepartners1.sharepoint.com/:p:/r/SharedServices/VisualDesign/_layouts/15/Doc.aspx?sourcedoc=%7BE7BE2267-EC22-4CF8-847E-7F12F2A75D9F%7D&file=Value%20Acceleration%20Discussion%20Slide%20Template.pptx&wdLOR=c8DFB12E5-15A5-6E46-8B22-7CFC6F2BB24C&action=edit&mobileredirect=true"
                              target="_blank"
                            >
                              Value Acceleration Discussion Slide Template.pptx
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ bgcolor: '#CDD2D7' }}>
                          <TableCell>
                            <b>Buyer Intelligence</b> <br />
                            <i>
                              Client Activities Report, Survey Responses, Key Stakeholder
                              Relationship Map, Influence Chart, Individual Client List, etc.
                            </i>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                              >
                                {buyerIntFiles.map((file, index) => (
                                  <FileCard
                                    key={index}
                                    file={file}
                                    handleFileDelete={() => {
                                      setBuyerIntFiles(buyerIntFiles.filter((_, i) => i !== index));
                                    }}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconButton component="label">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    name="buyer_int_files"
                                    onChange={(e) => {
                                      handleFileUpload(e, 'buyer_int_files');
                                    }}
                                    accept={allowedFileTypes}
                                  />
                                  <AttachFile sx={{ color: 'black' }} />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Link
                              href="https://westmonroepartners1.sharepoint.com/SharedServices/Marketing/marketingandbd/Forms/AllItems.aspx?id=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles%2FHow%20to%20Download%20Stakeholder%5FRelationship%20Map%2Epdf&parent=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles&p=true&ga=1"
                              target="_blank"
                            >
                              How to Download Stakeholder_Relationship Map
                            </Link>

                            <br />
                            <br />

                            <Link
                              href="https://westmonroepartners1.sharepoint.com/SharedServices/Marketing/marketingandbd/Forms/AllItems.aspx?id=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles%2FDownload%20Survey%20Responses%2Epdf&parent=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles&p=true&ga=1"
                              target="_blank"
                            >
                              How to Download Survey Responses
                            </Link>

                            <br />
                            <br />

                            <Link
                              href="https://westmonroepartners1.sharepoint.com/SharedServices/Marketing/marketingandbd/Forms/AllItems.aspx?id=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles%2FHow%20to%20Download%20Activities%20Reports%2Epdf&parent=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles&p=true&ga=1"
                              target="_blank"
                            >
                              How to Download Activities Reports
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ bgcolor: '#E7EAED' }}>
                          <TableCell>
                            <b>Internal Client Materials</b> <br />
                            <i>
                              Team Meeting Notes, Internal Training Materials on the Account, PY
                              Account Plans, Prolifiq Plans, etc.
                            </i>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                              >
                                {intMaterialsFiles.map((file, index) => (
                                  <FileCard
                                    key={index}
                                    file={file}
                                    handleFileDelete={() => {
                                      setIntMaterialsFiles(
                                        intMaterialsFiles.filter((_, i) => i !== index)
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconButton component="label">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    name="int_materials_files"
                                    onChange={(e) => {
                                      handleFileUpload(e, 'int_materials_files');
                                    }}
                                    accept={allowedFileTypes}
                                  />
                                  <AttachFile sx={{ color: 'black' }} />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Link
                              href="https://westmonroepartners1.sharepoint.com/SharedServices/Marketing/marketingandbd/Forms/AllItems.aspx?id=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles%2FHow%20to%20Download%20Prolifiq%20Account%20Plans%2Epdf&parent=%2FSharedServices%2FMarketing%2Fmarketingandbd%2FClient%20Marketing%2FAccount%20Growth%20Pilot%2FProject%20Materials%2FTraining%2FHow%2Dto%20Articles&p=true&ga=1"
                              target="_blank"
                            >
                              How to Download Prolifiq Account Plans
                            </Link>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ bgcolor: '#CDD2D7' }}>
                          <TableCell>
                            <b>External Client Materials</b> <br />
                            <i>Annual Reports, Strategic Charters, Client Financials, etc.</i>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                              >
                                {extMaterialsFiles.map((file, index) => (
                                  <FileCard
                                    key={index}
                                    file={file}
                                    handleFileDelete={() => {
                                      setExtMaterialsFiles(
                                        extMaterialsFiles.filter((_, i) => i !== index)
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                }}
                              >
                                <IconButton component="label">
                                  <input
                                    type="file"
                                    hidden
                                    multiple
                                    name="ext_materials_files"
                                    onChange={(e) => {
                                      handleFileUpload(e, 'ext_materials_files');
                                    }}
                                    accept={allowedFileTypes}
                                  />
                                  <AttachFile sx={{ color: 'black' }} />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            *If client materials are not publicly shared, please add your own here
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <StyledSubmitButton type="submit" variant="contained">
                        Submit
                      </StyledSubmitButton>
                    </div>
                  </Container>
                </StyledForm>
              )}
            />
          </Container>
        </DocumentUploadContainer>
      </div>
    </MainDiv>
  );
};

export default DocumentUpload;
