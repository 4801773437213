export function createMessageSnippet(message: string, searchQuery: string | undefined): string {
  const maxLength = 30;
  const closeToEnd = 10;
  if (!searchQuery) {
    return message.substring(0, maxLength);
  }

  const searchIndex = message.toLowerCase().indexOf(searchQuery.toLowerCase());

  if (searchIndex === -1) {
    return message.length <= maxLength ? message : message.slice(0, maxLength) + '...';
  }

  let start = Math.max(0, searchIndex - 40);
  let end = Math.min(message.length, searchIndex + searchQuery.length + closeToEnd);

  // Adjust start to nearest word boundary
  if (start > 0) {
    const wordBoundary = message.lastIndexOf(' ', start);
    if (wordBoundary !== -1) {
      start = wordBoundary + 1;
    }
  }

  // Adjust end to nearest sentence end
  const sentenceEnd = message.slice(searchIndex + searchQuery.length, end).search(/[.!?]/);
  if (sentenceEnd !== -1) {
    end = searchIndex + searchQuery.length + sentenceEnd + 1;
  }

  let snippet = message.slice(start, end);

  if (start > 0) snippet = '...' + snippet;
  if (end < message.length) snippet += '...';

  return '"' + snippet + '"';
}
