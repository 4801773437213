import { List, ListItem, Typography } from '@mui/material';
import React from 'react';

const Announcements: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: 'gray',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NIGEL FUN FACTS
      </Typography>
      <div style={{ height: 'inherit', display: 'flex' }}>
        <div>
          <List sx={{ height: 'inherit' }}>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">💃 Nigel was almost called Marilyn (Monroe)</Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">
                🏆 Nigel was named for NGL (Next Generation of Leaders)
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">
                🧑‍💻 Over 40 (and counting) engineers have contributed to Nigel
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">
                📈 Nigel has doubled its monthly users since its launch 1 year ago
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    </>
  );
};

export default Announcements;
