import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, IconButton, Menu, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useFeatures } from '../components/Providers/FeatureProvider';
import { Llm, LlmMapping } from '../Types/enums';
import { useIsMobile } from '../util/useIsMobile';
import { useAnalytics } from './Providers/AnalyticsProvider';

const LlmSelect = ({
  value,
  onLlmChange,
  mobileNavbarLlm,
}: {
  value: Llm;
  onLlmChange: (llm: Llm) => void;
  mobileNavbarLlm?: boolean;
}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [llm, setLLM] = useState<Llm>(value || Llm.GPT_4O_128K);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useIsMobile();
  const features = useFeatures();
  const analytics = useAnalytics();

  useEffect(() => {
    setLLM(value || Llm.GPT_4O_128K);
  }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newValue?: Llm) => {
    setAnchorEl(null);
    if (newValue) {
      onLlmChange(newValue);
    }
  };

  return (
    <Tooltip
      enterDelay={1000}
      open={tooltipOpen && !isMobile}
      title={
        <Typography variant="caption">
          The LLM selected will be used for all prompts and conversations
        </Typography>
      }
      placement="left"
      sx={{ height: '40px', width: '140px' }}
      id={'llm-select-description'}
    >
      {isMobile ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={mobileNavbarLlm ? 'flex-end' : 'flex-start'}
          width="100%"
        >
          <Typography variant="body1" onClick={handleClick} style={{ cursor: 'pointer' }}>
            {LlmMapping[value]}
          </Typography>
          <IconButton onClick={handleClick} size="small">
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
              style: {
                width: '100%',
                maxWidth: '100%',
                left: '0px',
                right: '0px',
                marginTop: '18px',
                marginLeft: '16px',
              },
            }}
          >
            <MenuItem
              disabled
              style={{
                marginTop: '-10px',
                whiteSpace: 'normal',
                opacity: 1,
                fontSize: '14px',
                color: 'white',
                background: '#757575',
                fontFamily: 'Montserrat, sans-serif',
              }}
            >
              The LLM selected will be used for all prompts and conversations
            </MenuItem>
            <MenuItem
              value={Llm.GPT_4O_128K}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              onClick={() => handleClose(Llm.GPT_4O_128K)}
            >
              {LlmMapping[Llm.GPT_4O_128K]}
              <Typography variant="body2" style={{ color: 'grey' }}>
                GPT-4 Turbo performance, 2x the speed
              </Typography>
              <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                (GPT 4o (omni) 128k tokens)
              </Typography>
            </MenuItem>
            <MenuItem
              value={Llm.GPT_4_32K}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              onClick={() => handleClose(Llm.GPT_4_32K)}
            >
              {LlmMapping[Llm.GPT_4_32K]}
              <Typography variant="body2" style={{ color: 'grey' }}>
                Longer conversations, smarter results
              </Typography>
              <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                (GPT 4 32k tokens)
              </Typography>
            </MenuItem>
            {features.gpt35_turbo_16k && (
              <MenuItem
                value={Llm.GPT_35_TURBO_16K}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                onClick={() => handleClose(Llm.GPT_35_TURBO_16K)}
              >
                {LlmMapping[Llm.GPT_35_TURBO_16K]}
                <Typography variant="body2" style={{ color: 'grey' }}>
                  Older model, still good for most conversations
                </Typography>
                <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                  (GPT 3.5 Turbo 16k tokens)
                </Typography>
              </MenuItem>
            )}
            {features.gpt4 && (
              <MenuItem value={Llm.GPT_4} onClick={() => handleClose(Llm.GPT_4)}>
                {LlmMapping[Llm.GPT_4]}
              </MenuItem>
            )}
            {features.gpt4_turbo_128k && (
              <MenuItem
                value={Llm.GPT_4_TURBO_128K}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                onClick={() => handleClose(Llm.GPT_4_TURBO_128K)}
              >
                {LlmMapping[Llm.GPT_4_TURBO_128K]}
                <Typography variant="body2" style={{ color: 'grey' }}>
                  Longer conversation, more knowledge of the world
                </Typography>
                <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                  (GPT-4 Turbo 128k tokens)
                </Typography>
              </MenuItem>
            )}
            {features.glean && (
              <MenuItem
                value={Llm.GLEAN}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                onClick={() => handleClose(Llm.GLEAN)}
              >
                {LlmMapping[Llm.GLEAN]}
                <Typography variant="body2" style={{ color: 'grey' }}>
                  Use Glean&apos;s endpoint for completions
                </Typography>
                <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                  (GPT 4 unknown tokens)
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      ) : (
        <Select<Llm>
          value={llm}
          aria-describedby={'llm-select-description'}
          id="llm-select"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          onChange={(event) => {
            const newValue = event.target.value as Llm;
            analytics.llmChange({ oldllm: llm, newLlm: newValue });
            setLLM(newValue);
            setTooltipOpen(false);
            onLlmChange(newValue);
          }}
          onClose={() => setTooltipOpen(false)}
          sx={{
            '& > *': { fontFamily: 'Noto Sans' },
            backgroundColor: 'white',
          }}
          renderValue={(value) => {
            switch (value) {
              case Llm.GPT_4_32K:
                return <Typography>{LlmMapping[Llm.GPT_4_32K]}</Typography>;
              case Llm.GPT_35_TURBO_16K:
                return <Typography>{LlmMapping[Llm.GPT_35_TURBO_16K]} </Typography>;
              case Llm.GPT_4:
                return <Typography>{LlmMapping[Llm.GPT_4]}</Typography>;
              case Llm.GPT_4_TURBO_128K:
                return <Typography>{LlmMapping[Llm.GPT_4_TURBO_128K]}</Typography>;
              case Llm.GPT_4O_128K:
                return <Typography>{LlmMapping[Llm.GPT_4O_128K]}</Typography>;
              case Llm.GLEAN:
                return <Typography>{LlmMapping[Llm.GLEAN]}</Typography>;
              default:
                return <Typography>{LlmMapping[Llm.GPT_4O_128K]}</Typography>;
            }
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            MenuListProps: { disablePadding: true },
          }}
          size="small"
        >
          <MenuItem
            value={Llm.GPT_4O_128K}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
          >
            {LlmMapping[Llm.GPT_4O_128K]}
            <Typography variant="body2" style={{ color: 'grey' }}>
              GPT-4 Turbo performance, 2x the speed
            </Typography>
            <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
              (GPT 4o (omni) 128k tokens)
            </Typography>
          </MenuItem>
          <MenuItem
            value={Llm.GPT_4_32K}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
          >
            {LlmMapping[Llm.GPT_4_32K]}
            <Typography variant="body2" style={{ color: 'grey' }}>
              Longer conversations, smarter results
            </Typography>
            <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
              (GPT 4 32k tokens)
            </Typography>
          </MenuItem>
          {features.gpt35_turbo_16k && (
            <MenuItem
              value={Llm.GPT_35_TURBO_16K}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              {LlmMapping[Llm.GPT_35_TURBO_16K]}
              <Typography variant="body2" style={{ color: 'grey' }}>
                Older model, still good for most conversations
              </Typography>
              <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                (GPT 3.5 Turbo 16k tokens)
              </Typography>
            </MenuItem>
          )}
          {features.gpt4 && <MenuItem value={Llm.GPT_4}>GPT 4 (non 32k)</MenuItem>}
          {features.gpt4_turbo_128k && (
            <MenuItem
              value={Llm.GPT_4_TURBO_128K}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              {LlmMapping[Llm.GPT_4_TURBO_128K]}
              <Typography variant="body2" style={{ color: 'grey' }}>
                Longer conversation, more knowledge of the world
              </Typography>
              <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                (GPT-4 Turbo 128k tokens)
              </Typography>
            </MenuItem>
          )}
          {features.glean && (
            <MenuItem
              value={Llm.GLEAN}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              {LlmMapping[Llm.GLEAN]}
              <Typography variant="body2" style={{ color: 'grey' }}>
                Use Glean&apos;s endpoint for completions
              </Typography>
              <Typography variant="caption1" style={{ color: 'grey', marginTop: '6px' }}>
                (GPT 4 unknown tokens)
              </Typography>
            </MenuItem>
          )}
        </Select>
      )}
    </Tooltip>
  );
};

export default LlmSelect;
