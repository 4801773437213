import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Container, styled, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { FeedbackContext } from '../../constants/feedbackConstants';
import { usePrevious } from '../../hooks/usePrevious';
import { updateFeedback } from '../../redux/actions/projectQuestionActions';
import { AppDispatch } from '../../redux/store';
import { validateFindings } from '../../services/dealGPT';
import type {
  QuestionType,
  QuestionTypeTreeNode,
  ValidateFindingsOptions,
} from '../../Types/dealGPT';
import { FeedbackInterface } from '../../Types/feedback';
import Feedback from '../Feedback';
import ReferenceDocuments from './PhaseTwo/ReferenceDocuments';
import StyledMarkdownTable from './PhaseTwo/StyledMarkdownTable';
import QuestionStatus from './QuestionStatus';

const QuestionViewContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '29px',
  margin: '40px auto',
  padding: '24px',
  paddingBottom: '60px',
});

const StyledButton = styled(Button)({
  width: '200px',
  height: '35px',
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const TextContainer = styled('p')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  fontFamily: 'Noto Sans',
});

export type QuestionViewProps = {
  questions: QuestionTypeTreeNode[];
  question: QuestionType;
  gleanAppId: string;
  questionsTree: QuestionTypeTreeNode[];
  setQuestions: (nodes: QuestionTypeTreeNode[]) => void;
  handleCloseQuestion: () => void;
};

const QuestionView = ({
  questions,
  question,
  gleanAppId,
  questionsTree,
  setQuestions,
  handleCloseQuestion,
}: QuestionViewProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [questionObject, setQuestionObject] = useState<QuestionType>();
  const [isLoadingValidation, setIsLoadingValidation] = useState<boolean>(false);
  const currentValidationFindingsContainerRef = useRef<null | HTMLDivElement>(null);
  const previousIsLoadingValidation = usePrevious(isLoadingValidation);

  const findandReplaceQuestion = (
    questions: QuestionTypeTreeNode[],
    questionToUpdate: QuestionType
  ) => {
    for (const question of questions) {
      if (Array.isArray(question) && question.id != questionToUpdate.id) {
        findandReplaceQuestion(question, questionToUpdate);
      } else {
        if (question.id == questionToUpdate.id) {
          question.validationGleanApiResponse = questionToUpdate.gleanApiResponse;
          question.validationResponse = questionToUpdate.validationResponse;
          question.validationSources = questionToUpdate.validationSources;
          question.projectQuestionFeedback = questionToUpdate.projectQuestionFeedback;
          break;
        }
      }
    }

    return questions;
  };

  const handleFeedbackSubmit = (feedback: FeedbackInterface, projectQuestionId: string) => {
    if (questionObject) {
      console.log('Feedback submitted:', feedback);
      dispatch(
        updateFeedback({ projectQuestionId: projectQuestionId, projectQuestionFeedback: feedback })
      );

      setQuestionObject((prevState) => {
        if (prevState) {
          const updatedQuestionObject = {
            ...prevState,
            projectQuestionFeedback: feedback,
          };
          const updatedQuestions = findandReplaceQuestion(questionsTree, updatedQuestionObject);
          setQuestions(updatedQuestions);
          return updatedQuestionObject;
        }
      });
    }
  };

  const handleValidateFindingsClick = async () => {
    setIsLoadingValidation(true);
    try {
      //Getting Validate Findigs for current question.
      const options: ValidateFindingsOptions = {
        response: questionObject?.response,
        question: questionObject?.question,
      };
      const responseValidation = questionObject
        ? await validateFindings(options, questionObject.id, gleanAppId)
        : undefined;
      if (responseValidation?._isSuccess) {
        if (responseValidation._data) {
          setQuestionObject(responseValidation._data);
          //Updating the question validation fields got from service.
          const questionData = responseValidation._data;
          const updatedQuestions = findandReplaceQuestion(questionsTree, questionData);

          //Updating questions array, then after closing question view, validation fields will load correctly.
          setQuestions(updatedQuestions);
        }
      } else {
        enqueueSnackbar(`Failed to validate findings: ${responseValidation?._error}`, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to validate findings: ${error}`, { variant: 'error' });
    }
    setIsLoadingValidation(false);
  };

  //After loading scroll into Validation Panel.
  useEffect(() => {
    if (previousIsLoadingValidation && !isLoadingValidation && questionObject?.validationResponse) {
      currentValidationFindingsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoadingValidation, previousIsLoadingValidation, questionObject?.validationResponse]);

  useEffect(() => {
    if (question) {
      const questionObject = questions.find((q) => q.question === question.question);
      if (questionObject) {
        setQuestionObject(questionObject);
      }
    }
  }, [question, questions]);

  if (!questionObject) {
    return <CircularProgress sx={{ marginTop: '80px' }} />;
  }

  return (
    <QuestionViewContainer maxWidth="sm">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" sx={{ fontSize: '16px', fontWeight: '600', lineHeight: '1.2em' }}>
          {questionObject.question}
        </Typography>
        <Button onClick={handleCloseQuestion} sx={{ padding: 0, minWidth: 'unset' }}>
          <CloseIcon sx={{ color: '#092B49' }} />
        </Button>
      </div>
      <div>
        <QuestionStatus status={questionObject.status} />
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Response</Typography>
        <Typography sx={{ fontWeight: '400' }}>{questionObject.response}</Typography>
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Findings</Typography>
        <ReactMarkdownWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledButton
              disabled={isLoadingValidation}
              variant="contained"
              onClick={handleValidateFindingsClick}
            >
              {questionObject.validationResponse ? 'Validate Findings Again' : 'Validate Findings'}
            </StyledButton>
            {isLoadingValidation ? <CircularProgress sx={{ marginLeft: '15px' }} /> : <></>}
          </div>
          <StyledMarkdownTable>
            <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
              {questionObject.explanation || ''}
            </ReactMarkdown>
          </StyledMarkdownTable>
        </ReactMarkdownWrapper>
      </div>
      <div>
        <Feedback
          initialFeedback={questionObject.projectQuestionFeedback as FeedbackInterface | undefined}
          context={FeedbackContext.PROJECT_QUESTION}
          onFeedbackSubmit={(feedback) => handleFeedbackSubmit(feedback, questionObject.id)}
        />
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Source(s)</Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {questionObject.documents?.length == 0 ? (
            <TextContainer>No sources found.</TextContainer>
          ) : (
            <ReferenceDocuments showAnalysisText={false} documents={questionObject.documents} />
          )}
        </div>
      </div>
      {isLoadingValidation ? (
        <CircularProgress sx={{ margin: 'auto' }} />
      ) : (
        questionObject.validationResponse && (
          <div ref={currentValidationFindingsContainerRef}>
            <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>
              Validation Source(s)
            </Typography>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '12px' }}
            >
              {questionObject.validationSources?.length == 0 ? (
                <TextContainer>No sources found.</TextContainer>
              ) : (
                <ReferenceDocuments
                  showAnalysisText={false}
                  documents={questionObject.validationSources}
                />
              )}
            </div>
            <Typography sx={{ fontWeight: '700' }}>Validation Findings</Typography>
            <ReactMarkdownWrapper>
              <StyledMarkdownTable>
                <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                  {questionObject.validationResponse || ''}
                </ReactMarkdown>
              </StyledMarkdownTable>
            </ReactMarkdownWrapper>
          </div>
        )
      )}
    </QuestionViewContainer>
  );
};

export default QuestionView;
