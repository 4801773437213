import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';

const BannerContainer = styled(Card)(({ theme: { palette } }) => ({
  width: '100%',
  transition: '0.3s',
  boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
  position: 'relative',
  overflow: 'initial',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: palette.utility.surface.tagPrimary,
}));

interface SpecialEventBannerProps {
  text: string;
}

const SpecialEventBanner = ({ text }: SpecialEventBannerProps) => {
  const [isVisible, setIsVisible] = useState(() => {
    return localStorage.getItem('bannerDismissed') !== 'true';
  });

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('bannerDismissed', 'true');
  };

  return isVisible ? (
    <BannerContainer>
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography>
        <p>{text}</p>
      </Typography>
    </BannerContainer>
  ) : null;
};

export default SpecialEventBanner;
