import { Container, Grid, IconButton, styled } from '@mui/material';
import { EditorContent } from '@tiptap/react';
import { Link as ReactLink } from 'react-router-dom';

import { useIsMobile } from '../util/useIsMobile';

export const drawerWidth = '350px';

export const PageContainer = styled('div')({
  display: 'flex',
  height: '100vh',

  'div:not(.ignore-position):not(.ignore-position *)': {
    position: 'relative',
  },
});

export const StyledLink = styled(ReactLink)({
  textDecoration: 'none',
  color: 'inherit',
  '&:active, &:visited, &:hover, &:focus': {
    color: 'inherit',
  },
});

export const StyledChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  minHeight: '100%',
  '& > *:not(:last-child)': {
    textAlign: 'center',
  },
});

export const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const SendButton = styled(IconButton)(() => {
  const isMobile = useIsMobile();
  return {
    paddingBottom: isMobile ? '68px' : '0',
    height: '40px',
    width: '40px',
    bottom: '4px',
  };
});

export const ProfileAndNotesDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

export const StyledGridContainer = styled(Grid)({
  display: 'flex',
  flexGrow: 1,
});

export const DrawerContainer = styled('div')(({ open }: { open: boolean }) => ({
  width: open ? drawerWidth : 'auto',
  zIndex: '1',
}));

export const MainContainer = styled('div')<{ open: boolean }>(({ open }) => ({
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
}));

export const TiptapStyled = styled(EditorContent)`
  .ProseMirror {
    border: none;
    border-radius: 4px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    outline: none;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 50px;
  }
`;

export const TiptapWrapper = styled('fieldset')({
  position: 'relative',
  display: 'block',
  alignItems: 'center',
  width: '100%',
  border: '2px solid #ccc',
  borderRadius: '4px',
  padding: '0',
  '&:focus-within': {
    borderColor: '#1C86E3',
    outline: 'none',
    '& legend': {
      color: '#1C86E3',
    },
  },
});

export const TiptapLabel = styled('legend')({
  padding: '0 5px',
  fontSize: '14px',
  color: '#666',
});

export const PromptEditTiptapStyled = styled(EditorContent)`
  .ProseMirror {
    border: none;
    bottom: 5px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    outline: none;
    padding-bottom: 70px;
    padding-left: 10px;
    padding-right: 50px;
  }
`;

export const PromptEditTiptapWrapper = styled('fieldset')({
  position: 'relative',
  display: 'block',
  alignItems: 'center',
  width: '100%',
  border: '1px solid lightgray',
  borderRadius: '4px',
  padding: '0',
  '&:focus-within': {
    borderColor: '#1C86E3',
    border: '2px solid #1C86E3',
    outline: 'none',
    '& legend': {
      color: '#1C86E3',
    },
  },
});

export const PromptEditTiptapLabel = styled('legend')({
  padding: '0 5px',
  fontSize: '13px',
  color: '#666',
  fontWeight: '400',
  letterSpacing: '0.03333em',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
});

export const PromptEditHelperText = styled('p')({
  fontSize: '0.75rem',
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: '400',
  letterSpacing: '0.03333em',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  marginLeft: '14px',
  lineHeight: '1.66',
  marginTop: '0px',
  marginBottom: '0',
});

export const IconsContainer = styled('div')({
  display: 'flex',
  gap: '20px',
});

export const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',
  cursor: 'pointer',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

export const ChatRow = styled('div')({
  display: 'flex',
  gap: '5px',
  marginBottom: '16px',
  alignItems: 'flex-end',
  position: 'sticky',
  bottom: '0',
  paddingTop: '10px',
});
