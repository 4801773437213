import { OpenInNew } from '@mui/icons-material';
import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

const StyledLink = styled('a')({
  color: 'black',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '0',
  paddingBottom: '10px',
});

const UserSpecificStats: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: 'gray',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        NIGEL USAGE
      </Typography>
      <div style={{ height: 'inherit' }}>
        <List sx={{ height: 'inherit', marginTop: '10px', paddingTop: '0', paddingBottom: '0' }}>
          <StyledListItem>
            <Typography variant="body2">
              <b>📊 Personal Stats</b>
            </Typography>
            <Typography variant="body2">
              Curious about your personal Nigel usage and stats? Click{' '}
              <StyledLink
                href={
                  'https://app.powerbi.com/links/0HyljkBk_F?ctid=c2a23be5-d2cf-4e70-84ce-18adf87d571f&pbi_source=linkShare'
                }
                target="_blank"
              >
                <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                <span style={{ verticalAlign: 'top' }}>
                  <b>HERE</b>
                </span>
              </StyledLink>{' '}
              to see how your usage compares to your department and the entire firm.
            </Typography>
          </StyledListItem>
          <StyledListItem>
            <Typography variant="body2">
              <b>💬 Nigel User Quote</b>
            </Typography>
            <Typography variant="body2">
              &quot;Nigel has helped automate multiple tasks in my job. It&apos;s excellent for
              summarizing meeting notes, reducing the effort needed for time-consuming tasks like
              reformatting.&quot;
            </Typography>
          </StyledListItem>
        </List>
      </div>
    </>
  );
};

export default UserSpecificStats;
